import { createAction, props } from '@ngrx/store';
export const loadNewNotificationCounter = createAction(
    '[Notification] Load New Notification Counter',
    props<{ userId: string }>(),
);

export const loadNewNotificationCounterSuccess = createAction(
    '[Notification] Load New Notification Counter Success',
    props<{ newNotificationsCount: number }>(),
);

export const increaseCountNotification = createAction(
    '[Notification] Increase Count'
);
