import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'rent-edit-button',
    templateUrl: './edit-button.component.html',
    styleUrls: ['./edit-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditButtonComponent {
    @Input() isDisabled = false;
}
