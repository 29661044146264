import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { metaReducers, ROOT_REDUCERS } from './store';
import { AuthEffects } from './store/auth/auth.effects';
import { EventEffects } from './store/current-event/current-event.effects';
import { WebsocketEffects } from './store/websocket/websocket.effects';
import { environment } from '../environments/environment';
import * as storeDevtools from '@ngrx/store-devtools';
import { NotificationEffects } from './store/notification/notification.effects';
import { ChattingEffects } from './store/chatting/chatting.effects';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { SharedModule } from './shared/components/shared.module';
import { ButtonsModule } from './shared/shared-modules/button/buttons.module';
import { AngularFireModule } from '@angular/fire';
import { firebaseAuthConfig } from 'src/environments/environment'
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MissingTranslationService } from './services/missing-translation.service';
import { FormsModule } from '@angular/forms';


export function HttpLoaderFactory(http: HttpClient): TranslateLoader {
  return new TranslateHttpLoader(http, './assets/locale/', '.json');
}
@NgModule({
    declarations: [AppComponent, PageNotFoundComponent],
    imports: [
        AngularFireModule.initializeApp(firebaseAuthConfig),
        AngularFireMessagingModule,
        ButtonsModule,
        SharedModule,
        BrowserModule,
        BrowserAnimationsModule,
        MatDialogModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        TranslateModule.forRoot({
        loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
            },
        missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MissingTranslationService },
        useDefaultLang: false,
        }),
        StoreModule.forRoot(ROOT_REDUCERS, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
            },
        }),
        storeDevtools.StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            logOnly: environment.production, // Restrict extension to log-only mode
        }),
        EffectsModule.forRoot([AuthEffects, EventEffects,  WebsocketEffects, ChattingEffects, NotificationEffects]),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
