import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { AuthService } from 'src/app/services/auth/auth.service';
import { mergeMapTo } from 'rxjs/operators';
import { State } from 'src/app/store';
import { Store } from '@ngrx/store';
import { INotification } from 'src/app/models/notification';
import { increaseCountNotification } from 'src/app/store/notification/notification.actions';

@Component({
    selector: 'receive-notifications',
    templateUrl: './receive-notifications.component.html',
    styleUrls: ['./receive-notifications.component.scss'],
})
export class ReceiveNotificationsComponent implements OnInit {
    notification: INotification | undefined;
    showNotification = false;
    timer = 10000;

    constructor(
        private authService: AuthService,

        private afMessaging: AngularFireMessaging,
        private cdRef: ChangeDetectorRef,
        private store: Store<State>,
    ) {
        this.requestPermission();
        this.receiveMessage();
    }

    ngOnInit(): void {}

    receiveMessage() {
        this.afMessaging.messages.pipe().subscribe((payload: any) => {
            this.notification = JSON.parse(payload.data.json);
            this.showNotification = true;
            this.store.dispatch(increaseCountNotification());
            setTimeout(() => (this.showNotification = false), this.timer);
            this.cdRef.markForCheck();
        });
    }

    requestPermission() {
        this.afMessaging.requestPermission
            .pipe(mergeMapTo(this.afMessaging.tokenChanges))
            .subscribe(
                (token) => {
                    if (token) {
                        this.authService
                            .updateNotification({ notification: { token } })
                            .subscribe();
                    }
                },
                (error) => {
                    console.error(error);
                },
            );
    }
    onDelete() {
        this.showNotification = false;
        this.cdRef.markForCheck();
    }
}
