import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { INotificationData } from 'src/app/models/notification';

@Injectable({
    providedIn: 'root',
})
export class NotificationsService {
    constructor(private apiService: ApiService) {}

    getListNotification(): Observable<INotificationData> {
        return this.apiService.getRequest('notifications', {desc: 'createdAt'}).pipe(map((resp) => resp?.data));
    }

    deleteNotification(id: string): Observable<INotificationData> {
        return this.apiService.deleteRequest(`notifications/${id}`);
    }
}
