import { Overlay } from '@angular/cdk/overlay';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of, Subscription, SubscriptionLike } from 'rxjs';
import { first } from 'rxjs/operators';
import { CURRENCY_LIST } from 'src/app/common-ts/constants';
import { IDropdownItem } from 'src/app/models/dropdowns';
import { IRole, IUser } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { State } from 'src/app/store';
import { signOut } from 'src/app/store/auth/auth.actions';
import { selectCurrentUser } from 'src/app/store/auth/auth.selectors';
import { selectNewMessagesCount } from 'src/app/store/chatting/chatting.selectors';
import { loadNewNotificationCounterSuccess } from 'src/app/store/notification/notification.actions';
import { selectNewNotificationCount } from 'src/app/store/notification/notification.selectors';
import { ConfirmDialogComponent } from '../../shared-modules/dialogs/confirm-dialog/confirm-dialog.component';
import { NotificationsDialogComponent } from '../../shared-modules/dialogs/notifications-dialog/notifications-dialog.component';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { LocalLanguageService } from 'src/app/services/local-language/local-language.service';
@Component({
    selector: 'rent-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy {
    isRouting = false;
    role: IRole | null = null;
    isAdmin = false;
    currentUser: IUser | null = null;
    newMessagesCount$: Observable<number> = this.store.select(selectNewMessagesCount);
    newNotificationsCount$: Observable<number> = this.store.select(selectNewNotificationCount);
    userSubscription: SubscriptionLike = Subscription.EMPTY;
    isShow = false;
    env = environment;
    languages: { id: string; title: string }[] = [];

    currencyService = {
        getList(): Observable<IDropdownItem[]> {
            return of(CURRENCY_LIST);
        },
    };

    @Input() isBackground = true;

    constructor(
        private router: Router,
        private store: Store<State>,
        private cdRef: ChangeDetectorRef,
        private matDialog: MatDialog,
        public languageService: LanguageService,
        private overlay: Overlay,
        private authService: AuthService,
        private translateService: TranslateService,
        public localLanguageService: LocalLanguageService,
    ) {}

    ngOnInit(): void {
        this.userSubscription = this.store
            .select(selectCurrentUser)
            .subscribe((user: IUser | null) => {
                this.currentUser = user;
                this.role = user?.role || null;
                this.isAdmin = this.role === 'ADMIN' || this.role === 'SUPER_ADMIN';
                this.cdRef.markForCheck();
            });
    }

    ngOnDestroy(): void {
        this.userSubscription.unsubscribe();
    }
    onSignOut(): void {
        this.matDialog
            .open(ConfirmDialogComponent, {
                width: '571px',
                backdropClass: 'backdrop__dark',
                panelClass: 'info-dialog',
                data: {
                    title: this.translateService.instant('Are you sure you want to log out?'),
                    confirmButton: 'Log out',
                    unConfirmButton: 'Cancel',
                },
            })
            .afterClosed()
            .subscribe((res) => {
                if (res) {
                    this.isRouting = true;
                    this.authService
                        .updateNotification({ notification: null })
                        .pipe(first())
                        .subscribe();
                    this.store.dispatch(signOut({ withRedirect: true }));
                }
            });
    }

    onNavigateToProfile(): void {
        if (this.isAdmin) {
            this.router.navigate(['/admin']);
        } else {
            const currentRoute = this.router.url;
            const targetRoute = `/${this.role?.toLowerCase()}/me`;
            if (currentRoute !== targetRoute) {
                this.isRouting = !currentRoute.includes('profile');
                this.router.navigate([targetRoute]).catch(() => (this.isRouting = false));
            }
        }
    }

    onChangedLanguage(language: any): void {
        this.translateService.use(language.query);
    }
    useLanguage(language: any) {
        this.translateService.use(language);
        this.localLanguageService.defaultLanguage$.next(language);
        this.setAttribute();
    }

    setAttribute() {
        if (
            this.localLanguageService.defaultLanguage$.value !== 'he' &&
            document.getElementsByTagName('body')[0].hasAttribute('dir')
        ) {
            document.getElementsByTagName('body')[0].removeAttribute('dir');
        } else if (
            this.localLanguageService.defaultLanguage$.value === 'he' &&
            !document.getElementsByTagName('body')[0].hasAttribute('dir')
        ) {
            document.getElementsByTagName('body')[0].setAttribute('dir', 'rtl');
        }
    }

    onChangedCurrency(currency: IDropdownItem): void {
        // to do
    }

    showNotification() {
        this.store.dispatch(loadNewNotificationCounterSuccess({ newNotificationsCount: 0 }));
        if (!this.isShow) {
            const scrollStrategy = this.overlay.scrollStrategies.reposition();
            this.matDialog
                .open(NotificationsDialogComponent, {
                    position: { top: '80px', right: '24px' },
                    panelClass: 'notification-dialog',
                    width: '310px',
                    hasBackdrop: false,
                    scrollStrategy,
                    data: {
                        isShow: false,
                    },
                })
                .afterClosed()
                .subscribe(() => {
                    this.isShow = false;
                });
            this.isShow = true;
        } else {
            this.isShow = false;
            this.matDialog.closeAll();
            return;
        }
    }
}
