import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromEvent from './current-event.reducer';

export const selectEventState = createFeatureSelector<fromEvent.State>(fromEvent.currentEventFeatureKey);

export const selectEventArtistStream = createSelector(selectEventState, (state) => state.artistStream);

export const selectEventUserStreams = createSelector(selectEventState, (state) => state.userStreams);

export const selectCurrentEvent = createSelector(selectEventState, (state) => state.currentEvent);

export const selectCurrentMicrophoneProps = createSelector(selectEventState, (state) => state.isMicrophoneOn);

export const selectCurrentCameraProps = createSelector(selectEventState, (state) => state.isCameraOn);

export const selectIsEventLoading = createSelector(selectEventState, (state) => state.isLoading);

export const selectIsEventErrors = createSelector(selectEventState, (state) => state.isErrors);

export const selectNewEventId = createSelector(selectEventState, (state) => state.newEventId);

export const selectCurrentEventOnlineViewers = createSelector(selectEventState, (state) => state.onlineViewers);

export const selectCurrentEventChatMessages = createSelector(selectEventState, (state) => state.chatMessages);

export const selectClientsReady = createSelector(
    selectEventState, (state) => ({ isClientReady: state.isClientReady, isArtistReady: state.isArtistReady })
);

export const selectEventTips = createSelector(selectEventState, (state) => state.tips);
