import { createAction, props } from '@ngrx/store';

export const websocketStartConnect = createAction(
    '[Websocket] Websocket Start Connect',
    props<{ token: string }>()
);

export const websocketConnectedSuccess = createAction(
    '[Websocket] Websocket Connected Success',
    props<{ isConnected: boolean }>()
);

export const websocketConnectedFailure = createAction(
    '[Websocket] Websocket Connected Failure',
    props<{ error: any }>()
);

export const websocketDisconnect = createAction(
    '[Websocket] Websocket Disconnect'
);
