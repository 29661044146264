import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, exhaustMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';

import * as ChattingActions from './chatting.actions';
import { ChatService } from 'src/app/services/chat/chat.service';
import { IChatListItem, IChatMessage } from 'src/app/models/user';
import { Store } from '@ngrx/store';
import { State } from './chatting.reducer';
import { UsersService } from 'src/app/services/users/users.service';
import { selectActiveChat } from './chatting.selectors';
import { WebsocketService } from 'src/app/services/websocket/websocket.service';
import { EventsService } from 'src/app/services/events/events.service';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialogComponent } from 'src/app/shared/shared-modules/dialogs/info-dialog/info-dialog.component';
import { getResponseErrorMessage } from 'src/app/common-ts/error-helpers';

@Injectable()
export class ChattingEffects {

    loadChatList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ChattingActions.loadChatList),
            concatMap(({params}) => this.chatService.getChatList(params)
                .pipe(
                    exhaustMap(({result}: { result: IChatListItem[], total: number}) => {
                        const activeChat = result[0] || null;
                        return [
                            ChattingActions.loadChatListSuccess({ chatList: result, activeChat }),
                            ...(activeChat ? [ChattingActions.loadActiveChatMessages({activeChat})] : [])
                        ];
                    }),
                    catchError((response: HttpErrorResponse) => {
                        return of(ChattingActions.loadChatListFailure({ error: response }));
                    })
                ))
        );
    });

    loadChatListByAgent$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ChattingActions.loadChatListByAgent),
            concatMap(({params}) => this.chatService.getChatListByAgent(params)
                .pipe(
                    tap(data => console.log('loadChatListByAgent$', data)),
                    exhaustMap(({result}: { result: IChatListItem[], total: number}) => {
                        const activeChat = result[0] || null;
                        return [
                            ChattingActions.loadChatListSuccessByAgent({ chatList: result, activeChat }),
                            ...(activeChat ? [ChattingActions.loadActiveChatMessages({activeChat})] : [])
                        ];
                    }),
                    catchError((response: HttpErrorResponse) => {
                        return of(ChattingActions.loadChatListFailure({ error: response }));
                    })
                ))
        );
    });

    loadActiveChatMessages$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ChattingActions.loadActiveChatMessages),
            concatMap(({activeChat}) => this.chatService.getChatMessagesByChatId(activeChat.id)
                .pipe(
                    map(({ result }: { result: IChatMessage[], total: number }) => {
                        return ChattingActions.loadActiveChatMessagesSuccess({ messages: result, activeChatId: activeChat.id });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.getErrorAlert(error);
                        return of(ChattingActions.loadActiveChatMessagesFailure({ error }));
                    })
                ))
        );
    });

    messageFromChat$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ChattingActions.messageFromChat),
            withLatestFrom(this.store.select(selectActiveChat)),
            tap(([{message, chat}, activeChat]: [{ message: IChatMessage, chat: IChatListItem }, IChatListItem | null ]) => {
                if (activeChat?.id === chat.id && activeChat?.userId === message?.creatorId) {
                    this.websocketService.emit('READ_MESSAGE_FROM_CHAT', { creatorId: message?.creatorId, messageId: message.id });
                }
            })
        );
    }, { dispatch: false });

    loadNewMessageCounter$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ChattingActions.loadNewMessageCounter),
            switchMap(({userId}) => this.usersService.getUserStats(userId)),
            map((user) => ChattingActions.loadNewMessageCounterSuccess({ count: user?.newMessagesCount || 0 }))
        );
    });

    changeStatusOfPrivateEvent$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ChattingActions.changeStatusOfPrivateEvent),
            concatMap(({eventBookingId, ation, chatId}) => this.eventsService.changeStatusOfPrivateEvent(eventBookingId, ation)
                .pipe(
                    map((updatedMessage: IChatMessage) => {
                        return ChattingActions.changeStatusOfPrivateEventSuccess({ updatedMessage, chatId });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.getErrorAlert(error);
                        return of(ChattingActions.changeStatusOfPrivateEventFailure({ error }));
                    })
                ))
        );
    });

    constructor(
        private eventsService: EventsService,
        private websocketService: WebsocketService,
        private store: Store<State>,
        private usersService: UsersService,
        private actions$: Actions,
        private matDialog: MatDialog,
        private chatService: ChatService,
    ) {}

    getErrorAlert(error: HttpErrorResponse): void {
        this.matDialog.open(InfoDialogComponent, {
            width: '571px',
            backdropClass: 'backdrop__dark',
            panelClass: 'info-dialog',
            data: {
                icon: { name: 'alert' },
                title: 'Ooops!',
                message: getResponseErrorMessage(error),
            },
        });
    }
}
