/* eslint-disable no-use-before-define */
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Input,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'rent-entities-input',
    templateUrl: './entities-input.component.html',
    styleUrls: ['./entities-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EntitiesInputComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntitiesInputComponent {
    activeEntityId: string | null = null;

    @Input() withEmptyOption = false;
    @Input() isDisabled = false;
    @Input() withLocalSave = false;
    @Input() service: any = null;
    @Input() placeholder = 'Choose';
    @Input() isDisabledCentering = true;
    @Input() dropdownClass = '';
    @Input() serviceParams: any = null;
    @Input() withFirstOption = false;

    constructor(private cdRef: ChangeDetectorRef) {}

    private propagateChange = (entity: any) => {};

    writeValue(entity: any): void {
        this.activeEntityId = entity?.id || null;
        this.cdRef.markForCheck();
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
        this.cdRef.markForCheck();
    }

    registerOnChange(fn: (entity: any) => void): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: boolean): void {}

    onChangedEntity(entity: any): void {
        if (entity) {
            this.propagateChange(entity);
        }
    }
}
