import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouteHistoryService } from 'src/app/services/route-history/route-history.service';

@Component({
    selector: 'rent-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageNotFoundComponent {
    constructor(
        private title: Title,
        public historyService: RouteHistoryService
    ) {
        this.title.setTitle('Page not found');
    }
}
