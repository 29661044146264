import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FeedbacksService } from 'src/app/services/feedbacks/feedbacks.service';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { catchError, switchMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';
import { getResponseErrorMessage } from 'src/app/common-ts/error-helpers';
import { throwError } from 'rxjs';

@Component({
    selector: 'app-feedback-about-agent',
    templateUrl: './feedback-about-agent.component.html',
    styleUrls: ['./feedback-about-agent.component.scss'],
})
export class FeedbackAboutAgentComponent implements OnInit {
    selectedStars = 1;
    feedback = '';

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            id: string;
        },
        private matDialogRef: MatDialogRef<FeedbackAboutAgentComponent>,
        private feedbacskService: FeedbacksService,
        private matDialog: MatDialog,
        private cdRef: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {}
    onClose(): void {
        this.matDialogRef.close(() => this.onClose());
    }
    onStarsSelected(stars: number): void {
        if (stars) {
            this.selectedStars = stars;
        }
    }
    onSend() {
        const params = {
            rating: this.selectedStars,
            description: this.feedback || null,
            agentId: this.data.id,
        };

        this.feedbacskService
            .createFeedback(params)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    this.matDialog.open(InfoDialogComponent, {
                        width: '571px',
                        backdropClass: 'backdrop__dark',
                        panelClass: 'info-dialog',
                        data: {
                            icon: { name: 'alert' },
                            title: 'Ooops!',
                            message: getResponseErrorMessage(error),
                        },
                    });
                    return throwError(error);
                }),
                switchMap(() => {
                    return this.matDialog
                        .open(InfoDialogComponent, {
                            width: '571px',
                            backdropClass: 'backdrop__dark',
                            panelClass: 'info-dialog',
                            data: {
                                icon: { name: 'checkmark-type2' },
                                title: 'Thanks for your feedback!',
                            },
                        })
                        .afterClosed();
                }),
            )
            .subscribe(() => {
                this.onClose();
            });
    }
}
