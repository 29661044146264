<div class="breadcrumbs__wrap">
    <ng-container *ngIf="withHomePage">
        <div [ngClass]="{'disabled': isDisabled}">
        <a routerLink="/" class="with-feedback">
            <mat-icon svgIcon="home"></mat-icon>
        </a>
        <mat-icon svgIcon="right-angle-bracket"></mat-icon>
        </div>
    </ng-container>
    <ng-container *ngFor="let item of breadcrumbs; let first=first; let last=last; let i=index">
        <mat-icon *ngIf="!first" svgIcon="right-angle-bracket"></mat-icon>
        <a 
            [ngClass]="{'with-feedback': item?.link, last: last}"
            [routerLink]="item?.link || []"
            [queryParams]="item?.query ? { query: item?.query } : {}">
            {{ item.title | translate : {Default: item.title} }}
        </a>
    </ng-container>
</div>
