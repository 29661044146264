import { Action, createReducer, on } from '@ngrx/store';
import * as WebsocketActions from './websocket.actions';

export const websocketFeatureKey = 'websocket';

export interface State {
    isWebsocketConnected: boolean;
}

export const initialState: State = {
    isWebsocketConnected: false,
};

const websocketReducer = createReducer(
    initialState,
    on(WebsocketActions.websocketStartConnect, (state) => ({
        ...state,
        isWebsocketConnected: false,
    })),
    on(WebsocketActions.websocketConnectedSuccess, (state, { isConnected }) => ({
        ...state,
        isWebsocketConnected: isConnected,
    })),
    on(WebsocketActions.websocketConnectedFailure, (state, { error }) => ({
        ...state,
        isWebsocketConnected: false,
    })),
    on(WebsocketActions.websocketDisconnect, (state) => ({
        ...state,
        isWebsocketConnected: false,
    })),
);

export function reducer(state: State | undefined, action: Action) {
    return websocketReducer(state, action);
}
