import { Injectable } from '@angular/core';
import {
    HttpErrorResponse,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { throwError } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State } from '../store';
import { signOut } from '../store/auth/auth.actions';
import { selectAuthToken } from '../store/auth/auth.selectors';

@Injectable({
    providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
    constructor(private store: Store<State>, private matDialog: MatDialog) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        return this.store.select(selectAuthToken).pipe(
            take(1),
            switchMap((token: string | null) => {
                let request = req;

                request = req.clone({
                    headers: req.headers.append('Accept', 'application/json'),
                });

                if (token) {
                    request = req.clone({
                        headers: req.headers.append('Authorization', `Bearer ${token}`),
                    });
                }

                return next.handle(request).pipe(
                    catchError((errorResponse: HttpErrorResponse) => {
                        const status = errorResponse.status;

                        if (status === 401) { // to do (and 404 && user_not_found)
                            this.store.dispatch(signOut({ withRedirect: false }));
                        }

                        return throwError(errorResponse);
                    }),
                );
            }),
        );
    }
}
