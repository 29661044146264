import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    OnDestroy,
    OnInit,
    HostListener,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription, SubscriptionLike } from 'rxjs';
import { filter } from 'rxjs/operators';
import { IRole } from 'src/app/models/user';
import { NotificationsService } from '../../../../services/notifications/notifications.service';

@Component({
    selector: 'app-notifications-dialog',
    templateUrl: './notifications-dialog.component.html',
    styleUrls: ['./notifications-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsDialogComponent implements OnInit, OnDestroy {
    notificationList: any;
    count: any;
    routerSubscription: SubscriptionLike = Subscription.EMPTY;
    authLink = "/auth/login"
    subscription: SubscriptionLike = Subscription.EMPTY;
    is_first : boolean = true;
    @ViewChild('dialog') dialog: ElementRef | undefined;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: NotificationsDialogComponent,
        private matDialogRef: MatDialogRef<NotificationsDialogComponent>,
        private cdRef: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.getList();
        this.routerSubscription = this.router.events
            .pipe(filter((event: any) => event instanceof NavigationEnd))
            .subscribe((event: any) => {
                const url = event.urlAfterRedirects;
                if (url === this.authLink) {
                    this.matDialogRef.close();
                }
            });
    }

    getList() {
        this.subscription = this.notificationsService.getListNotification().subscribe((data) => {
            this.notificationList = data.result;
            this.count = data.count;
            this.cdRef.markForCheck();
            if (data.count == 0) {
                setTimeout(() => {
                    this.onClose();
                }, 2000);
            }
        });
    }

    onClose(): void {
        this.matDialogRef.close();
    }
    onDelete(id: string) {
        this.notificationsService.deleteNotification(id).subscribe(() => {
            this.notificationList = this.notificationList.filter((item: any) => item.id !== id);
            this.cdRef.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.routerSubscription.unsubscribe();
    }

    @HostListener('document:click', ['$event'])
    public documentClick(event: Event): void {
        if(this.is_first){
            this.is_first = !this.is_first;
        }
        else if (!this.dialog?.nativeElement.contains(event.target)) {
            this.onClose();
        }
    }
}
