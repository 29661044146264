import { ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
    selector: 'rent-add-button',
    templateUrl: './add-button.component.html',
    styleUrls: ['./add-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddButtonComponent {
    @Input() text = 'Add more';
    @Input() isSmall = false;
    @Input() isDisabled = false;
}
