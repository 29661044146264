import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, UrlTree, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { State } from '../../store';
import { selectAuthenticated } from '../../store/auth/auth.selectors';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanLoad {
    constructor(private store: Store<State>, private router: Router) {}

    canActivate(): Observable<boolean | UrlTree> {
        return this.getPermissions();
    }

    canLoad(): Observable<boolean | UrlTree> {
        return this.getPermissions();
    }

    getPermissions(): Observable<boolean | UrlTree> {
        return this.store.pipe(select(selectAuthenticated)).pipe(
            take(1),
            map((isAuth: boolean) =>
            isAuth ? true : this.router.navigate(['/auth/login']) && false,
            ),
        );
    }
}
