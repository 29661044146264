import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';

import * as EventActions from './current-event.actions';
import { InfoDialogComponent } from 'src/app/shared/shared-modules/dialogs/info-dialog/info-dialog.component';
import { getResponseErrorMessage } from 'src/app/common-ts/error-helpers';
import { IEvent } from 'src/app/models/events';
import { EventsService } from 'src/app/services/events/events.service';
import { State } from '../index';
import { selectCurrentEvent } from './current-event.selectors';
import { loadCurrentUser } from '../auth/auth.actions';
import { MediaPublisherService } from '../../services/streaming/working-streaming/media-publisher.service';
import { MediaViewerService } from '../../services/streaming/working-streaming/media-viewer.service';
import { ChatService } from 'src/app/services/chat/chat.service';
import { ICurrentEventMessage, IUser } from 'src/app/models/user';
import { selectCurrentUser } from '../auth/auth.selectors';
import * as moment from 'moment';
import { InformationDialogComponent } from 'src/app/shared/shared-modules/dialogs/information-dialog/information-dialog.component';

@Injectable()
export class EventEffects {
    loadCurrentEvent$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(EventActions.loadCurrentEvent),
            concatMap(({ eventId, withChat, invitationToken }) =>
                (invitationToken
                    ? this.eventsService.activateEventInvitation(invitationToken)
                    : this.eventsService.getEventById(eventId)
                ).pipe(
                    concatMap((event: IEvent) => {
                        if (invitationToken) {
                            this.getSuccessDialog(
                                'You have received access to this private event!',
                            );
                        }
                        return [
                            EventActions.loadCurrentEventSuccess({
                                event: {
                                    ...event,
                                    ...(invitationToken ? { isBooked: true } : {}),
                                    start: moment(event.start).local().format('YYYY-MM-DDTHH:mm'),
                                    end: moment(event.end).local().format('YYYY-MM-DDTHH:mm'),
                                    standardTagIds: (event.standardTags || []).map((el) => el.id),
                                },
                            }),
                            ...(withChat
                                ? [
                                      EventActions.loadCurrentEventChat({ chatId: event.chatId }),
                                      EventActions.loadCurrentEventViewers({ eventId }),
                                      EventActions.loadCurrentEventTips({ eventId }),
                                  ]
                                : []),
                        ];
                    }),
                    catchError((response: HttpErrorResponse) => {
                        let text = '';
                        let buttonName = '';
                        const errors = response.error?.errors;
                        const isWrongId =
                            response.status === 400 &&
                            errors &&
                            errors[0].constraints?.includes('id must be a UUID');
                        if (invitationToken) {
                            text = 'Wrong user role or invitation token';
                            this.store.dispatch(
                                EventActions.loadCurrentEvent({ eventId, withChat }),
                            );
                        } else if (response.status === 404 || isWrongId) {
                            text = 'This event not exist';
                            this.router.navigate(['/']);
                        } else if (errors && errors[0]?.error === 'Forbidden') {
                            text = 'Sorry, you were denied access to the event.';
                            buttonName = 'Back to my profile';
                            this.router.navigate(['/']);
                            this.getNewErrorAlert(response, text, buttonName);

                            return of(EventActions.loadCurrentEventFailure({ error: response }));
                        }
                        this.getErrorAlert(response, text);

                        return of(EventActions.loadCurrentEventFailure({ error: response }));
                    }),
                ),
            ),
        );
    });

    updateCurrentEvent$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(EventActions.updateCurrentEvent),
            withLatestFrom(this.store.select(selectCurrentEvent)),
            concatMap(([_, event]) => {
                const updatedEvent = event
                    ? ({
                          title: event.title,
                          imageId: event.imageId,
                          description: event.description,
                          numberOfViewers: event.numberOfViewers,
                          price: event.price,
                          start: moment(event.start).toISOString(),
                          end: moment(event.end).toISOString(),
                          standardTagIds: event.standardTagIds,
                          ...(event.isNewPromocode && event.promocode?.type
                              ? {
                                    promocode: {
                                        type: event.promocode?.type,
                                        code: event.promocode?.code,
                                    },
                                }
                              : { promocode: null }),
                      } as IEvent)
                    : null;
                return this.eventsService.updateEvent(event?.id || '', updatedEvent).pipe(
                    map((event) => EventActions.updateCurrentEventSuccess({ event })),
                    catchError((error: HttpErrorResponse) => {
                        this.getErrorAlert(error);
                        return of(EventActions.updateCurrentEventFailure({ error }));
                    }),
                );
            }),
        );
    });

    createNewEvent$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(EventActions.createNewEvent),
            withLatestFrom(this.store.select(selectCurrentEvent)),
            concatMap(([action, newEvent]) => {
                if (newEvent && action.isPublic) {
                    const event = {
                        ...newEvent,
                        start: moment(newEvent.start).toISOString(),
                        end: moment(newEvent.end).toISOString(),
                    };
                    delete event.standardTags;
                    delete event.isNewPromocode;
                    return this.eventsService.createPublicEvent(event).pipe(
                        map((event) => {
                            this.store.dispatch(loadCurrentUser());
                            return EventActions.createNewEventSuccess({ event });
                        }),
                        catchError((error: HttpErrorResponse) => {
                            this.getErrorAlert(error);
                            return of(EventActions.createNewEventFailure({ error }));
                        }),
                    );
                } else {
                    return of(EventActions.createNewEventFailure({}));
                }
            }),
        );
    });

    clearCurrentEvent$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(EventActions.clearCurrentEvent),
            withLatestFrom(this.store.select(selectCurrentUser)),
            switchMap(([_, currentUser]) => {
                (currentUser?.role === 'ARTIST'
                    ? this.mediaPublisherService
                    : this.mediaViewerService
                ).unsubscribe();
                return of(EventActions.eventOperationSuccess());
            }),
        );
    });

    changeCameraState$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(EventActions.changeCameraState),
            switchMap((payload) => {
                (payload.userRole === 'ARTIST'
                    ? this.mediaPublisherService
                    : this.mediaViewerService
                ).changeCameraState(payload.state);
                return of(EventActions.eventOperationSuccess());
            }),
        );
    });

    changeMicrophoneState$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(EventActions.changeMicrophoneState),
            switchMap((payload) => {
                (payload.userRole === 'ARTIST'
                    ? this.mediaPublisherService
                    : this.mediaViewerService
                ).changeMicrophoneState(payload.state);
                return of(EventActions.eventOperationSuccess());
            }),
        );
    });

    leaveCall$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(EventActions.leaveCall),
            switchMap((payload) => {
                (payload.userRole === 'ARTIST'
                    ? this.mediaPublisherService
                    : this.mediaViewerService
                ).unsubscribe();
                return of(EventActions.eventOperationSuccess());
            }),
        );
    });

    connectToEvent$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(EventActions.connectToEvent),
            switchMap(({ userRole, eventId, userId, eventType }) => {
                return (userRole === 'ARTIST'
                    ? this.mediaPublisherService
                    : this.mediaViewerService
                ).connectToEvent(eventId, userId, eventType);
            }),
            switchMap(() => of(EventActions.eventOperationSuccess())),
        );
    });

    loadCurrentEventChat$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(EventActions.loadCurrentEventChat),
            concatMap(({ chatId }) =>
                this.chatService.getEventChatMessagesByEventId(chatId).pipe(
                    map(({ result, total }: { result: ICurrentEventMessage[]; total: number }) => {
                        return EventActions.loadCurrentEventChatSuccess({ chatMessages: result });
                    }),
                    catchError((response: HttpErrorResponse) => {
                        return of(EventActions.loadCurrentEventChatFailure({ error: response }));
                    }),
                ),
            ),
        );
    });

    loadCurrentEventViewers$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(EventActions.loadCurrentEventViewers),
            concatMap(({ eventId }) =>
                this.eventsService.getEventViewersByEventId(eventId).pipe(
                    map((onlineViewers: Partial<IUser>[]) => {
                        return EventActions.loadCurrentEventViewersSuccess({ onlineViewers });
                    }),
                    catchError((response: HttpErrorResponse) => {
                        return of(EventActions.loadCurrentEventViewersFailure({ error: response }));
                    }),
                ),
            ),
        );
    });

    loadCurrentEventTips$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(EventActions.loadCurrentEventTips),
            concatMap(({ eventId }) =>
                this.eventsService.getEventTipsByEventId(eventId).pipe(
                    map((tips: any[]) => {
                        return EventActions.loadCurrentEventTipsSuccess({ tips });
                    }),
                    catchError((response: HttpErrorResponse) => {
                        return of(EventActions.loadCurrentEventTipsFailure({ error: response }));
                    }),
                ),
            ),
        );
    });

    constructor(
        private store: Store<State>,
        private actions$: Actions,
        private eventsService: EventsService,
        private chatService: ChatService,
        private mediaPublisherService: MediaPublisherService,
        private mediaViewerService: MediaViewerService,
        private matDialog: MatDialog,
        private router: Router,
    ) {}

    getErrorAlert(error: HttpErrorResponse, text = ''): void {
        this.matDialog.open(InfoDialogComponent, {
            width: '571px',
            closeOnNavigation: false,
            backdropClass: 'backdrop__dark',
            panelClass: 'info-dialog',
            data: {
                icon: { name: 'alert' },
                title: 'Ooops!',
                message: text || getResponseErrorMessage(error),
            },
        });
    }

    getNewErrorAlert(error: HttpErrorResponse, text = '', buttonName = ''): void {
        this.matDialog.open(InformationDialogComponent, {
            width: '571px',
            backdropClass: 'backdrop__dark',
            panelClass: 'info-dialog',
            data: {
                icon: { name: 'sad-smile' },
                title: text || getResponseErrorMessage(error),
                buttonName,
            },
        });
    }

    getSuccessDialog(title = ''): void {
        this.matDialog.open(InfoDialogComponent, {
            width: '571px',
            closeOnNavigation: false,
            backdropClass: 'backdrop__dark',
            panelClass: 'info-dialog',
            data: {
                icon: { name: 'checkmark-type2' },
                title,
            },
        });
    }
}
