import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IChatMessage, IChatListItem, ICurrentEventMessage } from 'src/app/models/user';
import { ApiService } from '../api/api.service';

@Injectable({
    providedIn: 'root',
})
export class ChatService {
    private readonly url = 'chat';

    constructor(private apiService: ApiService) {}

    getChatIdByUserId(collocutorId: string): Observable<string> {
        return this.apiService.postRequest(this.url, {collocutorId}).pipe(map(resp => resp.data?.id));
    }

    getChatList(params?: { activeChatId?: string, search?: string }): Observable<{ result: IChatListItem[], total: number }> {
        return this.apiService.getRequest(this.url, params).pipe(map(res => res.data));
    }

    getChatListByAgent(params?: { activeChatId?: string, search?: string }): Observable<{ result: IChatListItem[], total: number }> {
        return this.apiService.getRequest(`${this.url}/artists`, params).pipe(map(res => res.data));
    }

    getChatMessagesByChatId(chatId: string): Observable<{ result: IChatMessage[], total: number }> {
        return this.apiService.getRequest(`${this.url}/${chatId}/messages`).pipe(map(res => res.data));
    }

    getEventChatMessagesByEventId(chatId: string): Observable<{ result: ICurrentEventMessage[], total: number }> {
        return this.apiService.getRequest(`${this.url}/${chatId}/event/messages`).pipe(map(res => res.data));
    }

    createChatMessage(chatId: string, params: Partial<IChatMessage>): Observable<IChatMessage> {
        return this.apiService.postRequest(`${this.url}/${chatId}/messages`, params).pipe(map(res => res.data));
    }
}
