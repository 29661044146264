import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'rent-badge',
    templateUrl: './badge.component.html',
    styleUrls: ['./badge.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent {
    @Input() count: number | null = null;
}
