<div class="list-item" [ngClass]="{ read: notification?.status==='READ' }">
    <div class="icon-close" (click)="onDelete(notification?.id)">
        <mat-icon svgIcon="close"></mat-icon>
    </div>
    <div class="icon {{color}}">
        <mat-icon svgIcon="{{svgType}}"></mat-icon>
    </div>
    <p class="description">
        {{notification?.title}}
    </p>
    <p class="time">{{notification?.createdAt | date: ' h:mm '}}</p>
    <rent-simple-button
        *ngIf="buttonText=== 'Go to event'"
        buttonClass="transparent"
        [buttonText]="buttonText"
        [routerLink]="routerLink + notification?.eventId"
    ></rent-simple-button>
    <rent-simple-button
        *ngIf="buttonText=== 'Leave a feedback'"
        buttonClass="transparent"
        [buttonText]="buttonText"
        (action)="openModal(notification?.agentId)"
    ></rent-simple-button>
</div>
