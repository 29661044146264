<button class="info-dialog__close-btn" (click)="onClose()">
    <mat-icon svgIcon="close"></mat-icon>
</button>
<div class="info-dialog__wrap confirm">
    <h4 [innerHTML]=data?.title></h4>
    <p *ngIf="data?.message as message">{{ message }}</p>
    <div class="controls" [ngClass]="{'single': !data.confirmButton}">
        <rent-simple-button
            mat-dialog-close
            buttonClass="mid"
            [buttonText]="data.unConfirmButton"
            (action)="onClose()"
        ></rent-simple-button>
        <rent-simple-button
            *ngIf="data.confirmButton"
            buttonClass="light"
            buttonType="submit"
            [buttonText]="data.confirmButton"
            (action)="onClose(true)"
        ></rent-simple-button>
    </div>
</div>
