import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IBookingFreeTimesData } from 'src/app/models/dates';
import { IEvent, IPrivateEvent } from 'src/app/models/events';
import { IChatMessage, IUser } from 'src/app/models/user';
import { ApiService } from '../api/api.service';

@Injectable({
    providedIn: 'root',
})
export class EventsService {
    private readonly urlPrivateEvent = 'event-booking';
    private readonly urlPublicEvent = 'event/public';
    private readonly urlActivateInvitation = 'event/invitation/activate';
    private readonly urlEvent = 'event';
    private readonly urlFreeTime = 'workday/free-time/prolong';

    constructor(private apiService: ApiService) {}

    getList(params: any): Observable<{ result: IEvent[], total: number }> {
        return this.apiService.getRequest(this.urlEvent, params).pipe(map(res => res.data));
    }
    getEvent(): Observable<IEvent> {
        return this.apiService.getRequest(`${this.urlEvent}`).pipe(map(res => res.data));
    }

    getEventById(eventId: string): Observable<IEvent> {
        return this.apiService.getRequest(`${this.urlEvent}/${eventId}`).pipe(map(res => res.data));
    }

    getEventViewersByEventId(eventId: string): Observable<Partial<IUser>[]> {
        return this.apiService.getRequest(`${this.urlEvent}/${eventId}/viewers`).pipe(map(res => res.data));
    }

    getEventTipsByEventId(eventId: string): Observable<Partial<any>[]> {
        return this.apiService.getRequest(`${this.urlEvent}/${eventId}/tips`).pipe(map(res => res.data?.result || []));
    }

    updateEvent(eventId: string, params?: Partial<IEvent> | null): Observable<IEvent> {
        return this.apiService.patchRequest(`${this.urlEvent}/${eventId}`, params).pipe(map(res => res.data));
    }

    cancelPublicOrPrivateEvent(eventId: string): Observable<any> {
        return this.apiService.deleteRequest(`${this.urlEvent}/${eventId}`);
    }

    prolongPrivateEvent(eventId: string, params?: any): Observable<any> {
        return this.apiService.postRequest(`${this.urlEvent}/${eventId}/prolong`, params);
    }

    getFreeTimeForProlong(params: {date: string, artistId: string}): Observable<IBookingFreeTimesData[]> {
        return this.apiService.getRequest(this.urlFreeTime, params).pipe(map(resp => resp.data));
    }

    cancelEventInvitation(invitationId: string): Observable<IEvent> {
        return this.apiService.deleteRequest(`${this.urlEvent}/invitation/${invitationId}`).pipe(map(res => res.data));
    }

    addEventInvitation(eventId: string, params?: any): Observable<IEvent> {
        return this.apiService.postRequest(`${this.urlEvent}/${eventId}/invitation`, params).pipe(map(res => res.data));
    }

    activateEventInvitation(invitationToken: string): Observable<IEvent> {
        return this.apiService.postRequest(this.urlActivateInvitation, { invitationToken }).pipe(map(res => res.data));
    }

    resendEventInvitation(invitationId: string, params?: any): Observable<any> {
        return this.apiService.postRequest(`${this.urlEvent}/invitation/${invitationId}/resend`, params);
    }

    createPublicEvent(params: Partial<IEvent>): Observable<IEvent> {
        return this.apiService.postRequest(this.urlPublicEvent, params).pipe(map(res => res.data));
    }

    getPrivateEvents(params?: any): Observable<IPrivateEvent> {
        return this.apiService.getRequest(this.urlPrivateEvent, params).pipe(map(res => res.data));
    }

    bookedPrivateEvent(params: Partial<IPrivateEvent>): Observable<IPrivateEvent> {
        return this.apiService.postRequest(this.urlPrivateEvent, params).pipe(map(res => res.data));
    }

    returnPublicEventTicket(eventId: string): Observable<any> {
        return this.apiService.deleteRequest(`${this.urlPublicEvent}/${eventId}/book`).pipe(map(res => res.data));
    }

    deletePrivateEventBooking(eventId: string): Observable<any> {
        return this.apiService.deleteRequest(`${this.urlPrivateEvent}/${eventId}`).pipe(map(res => res.data));
    }

    changeStatusOfPrivateEvent(eventBookingId: string, action: 'ACCEPT' | 'DECLINE' | string): Observable<IChatMessage> {
        return this.apiService.patchRequest(`${this.urlPrivateEvent}/${eventBookingId}/status`, {action}).pipe(map(res => res.data));
    }

    bookedPublicEvent(eventId: string, discount?: number, promoCode?: string): Observable<boolean> {
        const url = discount ? `${this.urlPublicEvent}/${eventId}/book/${promoCode}` : `${this.urlPublicEvent}/${eventId}/book`;
        return this.apiService.patchRequest(url, {id: eventId});
    }
}
