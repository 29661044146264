<div class="choose-star-row__wrap">
    <img
        *ngFor="let star of allStars; let index=index"
        [ngClass]="{active: (hoverStars > index || selectedStars > index)}"
        [tabindex]="index"
        [src]="(hoverStars > index || !hoverStars && selectedStars > index) ? pathFullStar : pathEmptyStar"
        (mouseenter)="onMouseenter($event)"
        (mouseleave)="onClearHover()"
        (click)="onSelected(index + 1)"
    >
</div>
