import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { IDropdownItem } from 'src/app/models/dropdowns';

@Component({
    selector: 'rent-entities-dropdown',
    templateUrl: './entities-dropdown.component.html',
    styleUrls: ['./entities-dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntitiesDropdownComponent implements AfterViewInit, OnInit {
    entityList: any[] = [];
    selectedEntity?: any;
    currParams?: any;
    currService?: any;
    prevValue: any;
    destroy$: Subject<boolean> = new Subject();

    control = new FormControl();

    @Input() activeEntityId: string | null = null;
    @Input() placeholder = 'Choose';
    @Input() isBorder = true;
    @Input() isBackground = true;
    @Input() isHover = false;
    @Input() isDisabledCentering = true;
    @Input() dropdownClass = '';
    @Input() isDisabled = false;
    @Input() withFirstOption = false;
    @Input() withEmptyOption = false;
    @Input() titleForEmptyOption = 'Not selected';
    @Input() withToolTip = false;

    @Input() set serviceParams(params: any) {
        this.currParams = params;
        if (params && this.currService) {
            this.getEntityList();
        }
    }

    @Input() set service(service: any) {
        if (service) {
            this.currService = service;
            this.getEntityList();
        }
    }

    @Output() selected = new EventEmitter<any>();

    @ViewChild(MatSelect) select?: MatSelect;

    constructor(private cdRef: ChangeDetectorRef) {}
    ngOnInit(): void {
        if (this.currService.language$$ && this.currParams === 'user') {
            this.currService.language$$.subscribe((data: IDropdownItem[]) => {
                this.entityList = data;
            });
        }
        if (this.currService.avaibleList$) {
            this.currService.avaibleList$.subscribe((data: IDropdownItem[]) => {
                this.entityList = data;
            });
        }
        if (this.currService.intervalOfEvent$) {
            this.currService.intervalOfEvent$.pipe(take(1)).subscribe((data: IDropdownItem[]) => {
                this.entityList = data;
            });
        }
    }

    ngAfterViewInit(): void {
        if (this.select) {
            this.select._positions = this.isDisabledCentering
                ? [
                      {
                          originX: 'start',
                          originY: 'bottom',
                          overlayX: 'start',
                          overlayY: 'top',
                      },
                      {
                          originX: 'start',
                          originY: 'top',
                          overlayX: 'start',
                          overlayY: 'bottom',
                      },
                  ]
                : [
                      {
                          originX: 'start',
                          originY: 'center',
                          overlayX: 'start',
                          overlayY: 'top',
                      },
                  ];
        }
    }

    getEntityList(): void {
        this.selectedEntity = null;
        this.currService
            .getList(this.currParams)
            .pipe(take(1))
            .subscribe((entities: any[] = []) => {
                if (this.withEmptyOption) {
                    this.entityList = [{ id: null, title: this.titleForEmptyOption }, ...entities];
                } else {
                    this.entityList = entities;
                }

                if (this.activeEntityId) {
                    this.setActiveEntity();
                } else if (this.withFirstOption) {
                    this.setFirstOption();
                }
                this.cdRef.detectChanges();
            });
    }

    setActiveEntity(): void {
        this.selectedEntity = this.entityList.find(
            (entity: any) => entity.id === this.activeEntityId,
        );
        this.selected.emit(this.selectedEntity);

        const newLanguagesList = this.entityList.map((language: IDropdownItem) => {
            if (JSON.stringify(this.selectedEntity) === JSON.stringify(language)) {
                return (this.selectedEntity = { ...this.selectedEntity, selected: true });
            } else {
                return language;
            }
        });

        if (this.currService.language$$ && this.currParams === 'user') {
            this.currService.language$$.next(newLanguagesList);
        }
    }

    setFirstOption(): void {
        this.selectedEntity = this.entityList[0];
        this.selected.emit(this.selectedEntity);
    }

    compareObjects(entity1: any, entity2: any): boolean {
        return entity1?.id === entity2?.id;
    }

    onSelectionChange(changes: MatSelectChange): void {
        this.selectedEntity = changes.value;
        this.selected.emit(this.selectedEntity);

        const newLanguagesList = this.entityList.map((language: IDropdownItem) => {
            if (JSON.stringify(this.selectedEntity) === JSON.stringify(language)) {
                return (this.selectedEntity = { ...this.selectedEntity, selected: true });
            } else if (JSON.stringify(this.prevValue) === JSON.stringify(language)) {
                return (this.prevValue = { ...this.prevValue, selected: false });
            } else {
                return language;
            }
        });

        if (this.currService.language$$ && this.currParams === 'user') {
            this.currService.language$$.next(newLanguagesList);
        }
    }

    onPrevValue(prevValue: any) {
        this.prevValue = prevValue;
    }
}
