/* eslint-disable */

import {WebSocketAdaptor} from './web-socket-adaptor';
import {EventEmitter} from '@angular/core';
import {PeerStats} from './peer-stats';

interface IWebRTCAdaptorInitialValues {
    mediaConstraints: IMediaConstraints;
    onlyDataChannel?: boolean;
    debug: boolean;
    isPlayMode?: boolean;
    localVideo?: HTMLMediaElement;
    remoteVideo?: HTMLMediaElement;
    callback: EventEmitter<ICallback>;
    callbackError: EventEmitter<ICallbackError>;
    peerConnectionConfig?: RTCConfiguration;
    sdpConstraints?: RTCOfferOptions;
}

export interface ICallback {
    type: string;
    value?: any;
}

export interface ICallbackError {
    name: string;
    value?: any;
}

interface IMediaConstraints {
    video: boolean;
    audio: boolean;
}

enum DataChannelMode {
    PUBLISH = 'publish',
    PLAY = 'play',
    PEER = 'peer'
}

enum PublishMode {
    CAMERA = 'CAMERA',
    SCREEN = 'SCREEN',
    SCREEN_CAMERA = 'SCREEN_CAMERA'
}

interface CanvasElement extends HTMLCanvasElement {
    captureStream(frameRate?: number): MediaStream;
}

export class WebRTCAdaptor {
    public peerConnectionConfig: RTCConfiguration | null = null;
    public sdpConstraints: RTCOfferOptions | null = null;
    public remotePeerConnection: Record<string, RTCPeerConnection | null> = {};
    public remotePeerConnectionStats: Record<string, PeerStats> = {};
    public remoteDescriptionSet: Record<string, boolean> = {};
    public iceCandidateList: Record<string, RTCIceCandidate[]> = {};
    public videoTrackSender: RTCRtpSender | null = null;
    public audioTrackSender: RTCRtpSender | null = null;
    public playStreamId: string[] = [];
    public currentVolume: number | null = null;
    public originalAudioTrackGainNode: MediaStreamTrack | null = null;
    public videoTrack: MediaStreamTrack | null = null;
    public audioTrack: MediaStreamTrack | null = null;
    public smallVideoTrack: MediaStreamTrack | null = null;
    public audioContext: AudioContext | null = null;
    public soundOriginGainNode: GainNode | null = null;
    public secondStreamGainNode: GainNode | null = null;
    public localStream: MediaStream | null = null;
    public isPlayMode: boolean;
    public bandwidth = 900; // default bandwidth kbps
    public isMultiPeer = false; // used for multiple peer client;
    public multiPeerStreamId = null;   // used for multiple peer client;
    public webSocketAdaptor: WebSocketAdaptor | null = null;
    public debug;
    public onlyDataChannel = false;
    public publishMode = PublishMode.CAMERA;
    public candidateTypes = ['udp', 'tcp'];
    public desktopStream: MediaStream | null = null;
    public readonly cameraMargin = 15;
    public readonly cameraPercent = 15;
    public mediaConstraints: IMediaConstraints;
    public callback: EventEmitter<ICallback>;
    public callbackError: EventEmitter<ICallbackError>;
    private streamIdForClose: string;

    constructor(initialValues: IWebRTCAdaptorInitialValues) {
        this.peerConnectionConfig = initialValues.peerConnectionConfig || null;
        this.sdpConstraints = initialValues.sdpConstraints || null;
        this.mediaConstraints = initialValues.mediaConstraints;
        this.onlyDataChannel = !!initialValues.onlyDataChannel;
        this.debug = initialValues.debug;
        this.isPlayMode = !!initialValues.isPlayMode;
        this.callback = initialValues.callback;
        this.callbackError = initialValues.callbackError;
        this.streamIdForClose = '';
        this.init().then();
    }

    public async init(): Promise<void> {
        if (!this.isPlayMode && !this.onlyDataChannel && this.localStream === null) {
            this.checkWebRTCPermissions();

            // Get devices only in publish mode.
            await this.getDevices();
            await this.trackDeviceChange();

            if (this.mediaConstraints.video) {
                await this.openStream(this.mediaConstraints);
            } else {
                const stream = await this.navigatorUserMedia({ audio: this.mediaConstraints.audio } , true);
                if (stream) {
                    await this.gotStream(stream);
                }
            }
        } else {
            // just playing, it does not open any stream
            this.checkWebSocketConnection();
        }
    }

    public async setDesktopWithCameraSource(stream: MediaStream,
                                            streamId: string,
                                            audioStream: MediaStream,
                                            onEndedCallback: any): Promise<void> {
        this.desktopStream = stream;
        const cameraStream = await this.navigatorUserMedia({video: true, audio: false}, true);
        if (cameraStream) {
            this.smallVideoTrack = cameraStream.getVideoTracks()[0];
            // create a canvas element
            const canvas: CanvasElement = document.createElement('canvas') as CanvasElement;
            const canvasContext = canvas.getContext('2d');

            // create video element for screen
            const screenVideo = document.createElement('video');

            screenVideo.srcObject = stream;
            await screenVideo.play();
            // create video element for camera
            const cameraVideo = document.createElement('video');

            cameraVideo.srcObject = cameraStream;
            await cameraVideo.play();
            const canvasStream = canvas.captureStream(15);

            this.localStream === null ? await this.gotStream(canvasStream) :
                this.updateVideoTrack(canvasStream, streamId, this.mediaConstraints, onended, null);

            if (onEndedCallback !== null) {
                stream.getVideoTracks()[0].onended = (event: Event) => {
                    onEndedCallback(event);
                };
            }

            // update the canvas
            setInterval(() => {
                // draw screen to canvas
                canvas.width = screenVideo.videoWidth;
                canvas.height = screenVideo.videoHeight;
                canvasContext?.drawImage(screenVideo, 0, 0, canvas.width, canvas.height);

                const cameraWidth = screenVideo.videoWidth * (this.cameraPercent / 100);
                const cameraHeight = (cameraVideo.videoHeight / cameraVideo.videoWidth) * cameraWidth;

                const positionX = (canvas.width - cameraWidth) - this.cameraMargin;
                const positionY = this.cameraMargin;

                canvasContext?.drawImage(cameraVideo, positionX, positionY, cameraWidth, cameraHeight);
            }, 66);
        }
    }

    public async trackDeviceChange(): Promise<void> {
        navigator.mediaDevices.ondevicechange = () => {
            this.getDevices();
        };
    }

    public async getDevices(): Promise<void> {
        const devices = await navigator.mediaDevices.enumerateDevices().catch(err => {
            console.error(`Cannot get devices -> error name: ${err.name}: ${err.message}`);
        });
        if (devices) {
            const deviceArray: MediaDeviceInfo[] = [];
            let checkAudio = false;
            devices.forEach(device => {
                if (device.kind === 'audioinput' || device.kind === 'videoinput') {
                    deviceArray.push(device);
                    if (device.kind === 'audioinput'){
                        checkAudio = true;
                    }
                }
            });
            this.callback.emit({type: 'available_devices', value: deviceArray});
            if (!checkAudio && this.localStream === null){
                console.error('Audio input not found');
                console.error('Retrying to get user media without audio');
                await this.openStream({video : true, audio : false});
            }
        }
    }

    public async prepareStreamTracks(mediaConstraints: IMediaConstraints, stream: MediaStream, streamId: string = ''): Promise<void> {
        // this trick, getting audio and video separately, make us add or remove tracks on the fly
        const audioTrack = stream.getAudioTracks();
        if (audioTrack.length > 0 && this.publishMode === PublishMode.CAMERA) {
            audioTrack[0].stop();
            stream.removeTrack(audioTrack[0]);
        }
        // now get only audio to add this stream
        if (mediaConstraints.audio) {
            let audioStream = await this.navigatorUserMedia({ audio: mediaConstraints.audio}, true);
            if (audioStream) {
                audioStream = this.setGainNodeStream(audioStream);
                if (this.originalAudioTrackGainNode !== null) {
                    // @ts-ignore
                    this.originalAudioTrackGainNode.stop();
                }
                this.originalAudioTrackGainNode = (audioStream as MediaStream).getAudioTracks()[1];

                // add callback if desktop is sharing
                const onended = () => {
                    this.callback.emit({type: 'screen_share_stopped'});
                    this.setVideoCameraSource(streamId, mediaConstraints, null, true);
                };

                switch (this.publishMode) {
                    case PublishMode.SCREEN:
                        this.updateVideoTrack(stream, streamId, mediaConstraints, onended, true);
                        if (audioTrack.length > 0) {
                            const mixedStream = this.mixAudioStreams(stream, audioStream);
                            this.updateAudioTrack(mixedStream, streamId, null);
                        } else {
                            this.updateAudioTrack(audioStream, streamId, null);
                        }
                        break;
                    case PublishMode.SCREEN_CAMERA:
                        if (audioTrack.length > 0) {
                            const mixedStream = this.mixAudioStreams(stream, audioStream);
                            this.updateAudioTrack(mixedStream, streamId, null);
                            await this.setDesktopWithCameraSource(stream, streamId, mixedStream, onended);
                        } else {
                            this.updateAudioTrack(audioStream, streamId, null);
                            await this.setDesktopWithCameraSource(stream, streamId, audioStream as MediaStream, onended);
                        }
                        break;
                    default:
                        if (mediaConstraints.audio) {
                            stream.addTrack((audioStream as MediaStream).getAudioTracks()[0]);
                        }
                        await this.gotStream(stream);
                }
                this.checkWebSocketConnection();
            }
        }
        else {
            await this.gotStream(stream);
        }
    }

    public async navigatorUserMedia(mediaConstraints: Partial<IMediaConstraints>, catchError: boolean): Promise<MediaStream | void> {
        return navigator.mediaDevices.getUserMedia(mediaConstraints).catch(error => {
            if (catchError) {
                error.name === 'NotFoundError' ? this.getDevices() : this.callbackError.emit({
                    name: error.name,
                    value: error.message
                });
            }
        });
    }

    /**
     * Get user media
     */
    public async getUserMedia(mediaConstraints: IMediaConstraints, streamId: string = ''): Promise<void> {

        // Check Media Constraint video value screen or screen + camera : If mediaConstraints only user camera
        const stream = (this.publishMode === PublishMode.SCREEN_CAMERA || this.publishMode === PublishMode.SCREEN) ?
            await (navigator.mediaDevices as any).getDisplayMedia(mediaConstraints).catch((error: Error) => {
                if (error.name === 'NotAllowedError') {
                    console.debug('Permission denied error');
                    this.callbackError.emit({name: 'ScreenSharePermissionDenied'});
                    // Redirect Default Stream Camera
                    this.localStream ? this.openStream({video: true, audio: true}) : this.switchVideoCameraCapture(streamId);
                }
            }) : await this.navigatorUserMedia(mediaConstraints, true);

        if (stream) {
            const videoTracks = stream.getVideoTracks();
            const audioTracks = stream.getAudioTracks();

            if (videoTracks.length > 0) {
                if (this.videoTrack !== null) {
                    this.videoTrack.stop();
                }
                this.videoTrack = videoTracks[0];
            }

            if (audioTracks.length > 0) {
                if (this.audioTrack !== null) {
                    this.audioTrack.stop();
                }
                this.audioTrack = audioTracks[0];
            }

            if (this.smallVideoTrack) {
                this.smallVideoTrack.stop();
            }
            await this.prepareStreamTracks(mediaConstraints, stream, streamId);
        }
    }

    /**
     * Open media stream, it may be screen, camera or audio
     */
    public async openStream(mediaConstraints: IMediaConstraints): Promise<void> {
        this.mediaConstraints = mediaConstraints;
        await this.getUserMedia(mediaConstraints);
    }

    /**
     * Closes stream, if you want to stop peer connection, call stop(streamId)
     */
    public closeStream(): void {
        console.log('Method is called', this.localStream);
        if (this.localStream) {
            this.stop(this.streamIdForClose);
            (this.localStream as MediaStream).getVideoTracks().forEach((track) => {
                track.onended = null;
                track.stop();
            });

            (this.localStream as MediaStream).getAudioTracks().forEach((track) => {
                track.onended = null;
                track.stop();
            });
        }
        if (this.videoTrack !== null) {
            this.videoTrack.stop();
        }

        if (this.audioTrack !== null) {
            this.audioTrack.stop();
        }

        if (this.smallVideoTrack !== null) {
            this.smallVideoTrack.stop();
        }
        if (this.originalAudioTrackGainNode) {
            this.originalAudioTrackGainNode.stop();
        }
    }
    /*
    * Checks if we is permitted from browser
    */
    public checkWebRTCPermissions(): void {
        if (!('WebSocket' in window)) {
            console.log('WebSocket not supported.');
            this.callbackError.emit({name: 'WebSocketNotSupported'});
            return;
        }
        if (!navigator.mediaDevices && !this.isPlayMode) {
            console.log('Cannot open camera and mic because of unsecure context. Please Install SSL(https)');
            this.callbackError.emit({name: 'UnsecureContext'});
            return;
        }
        if (!navigator.mediaDevices) {
            this.callbackError.emit({name: 'getUserMediaIsNotAllowed'});
        }
    }

    public enableSecondStreamInMixedAudio(enable: boolean): void {
        if (this.secondStreamGainNode) {
            this.secondStreamGainNode.gain.value = enable ? 1 : 0;
        }
    }

    public async publish(
        streamId: string,
        token: string,
        subscriberId: string = '',
        subscriberCode: string = ''
    ): Promise<void> {
        const jsCmd = {
            command: 'publish',
            streamId,
            token,
            subscriberId,
            subscriberCode,
            video: false,
            audio: false,
        };

        if (!this.onlyDataChannel) {
            if (!this.localStream) {
                const stream = await this.navigatorUserMedia(this.mediaConstraints, false);
                if (stream) {
                    await this.gotStream(stream);
                    (this.webSocketAdaptor as WebSocketAdaptor).send(JSON.stringify(jsCmd));
                }
                return;
            }
            jsCmd.audio = !!(this.localStream as MediaStream).getAudioTracks().length;
            jsCmd.video = !!(this.localStream as MediaStream).getVideoTracks().length;
        }
        (this.webSocketAdaptor as WebSocketAdaptor).send(JSON.stringify(jsCmd));
    }

    public joinRoom(roomName: string, streamId: string): void {
        (this.webSocketAdaptor as WebSocketAdaptor).send(JSON.stringify({
            command : 'joinRoom',
            room: roomName,
            streamId,
        }));
    }

    public play(
        streamId: string,
        token: string,
        room: string,
        trackList = [],
        subscriberId: string = '',
        subscriberCode: string = ''
    ): void {
        this.playStreamId.push(streamId);
        (this.webSocketAdaptor as WebSocketAdaptor).send(JSON.stringify({
            command: 'play',
            streamId,
            token,
            room,  // roomId
            trackList, // enableTracks
            subscriberId,
            subscriberCode,
            viewerInfo: '',
        }));
    }

    public stop(streamId: string): void {
        this.closePeerConnection(streamId);
        if (this.webSocketAdaptor) {
            (this.webSocketAdaptor as WebSocketAdaptor).send(JSON.stringify({
                command : 'stop',
                streamId,
            }));
        }
    }

    public join(streamId: string): void {
        (this.webSocketAdaptor as WebSocketAdaptor).send(JSON.stringify({
            command : 'join',
            streamId,
            multiPeer : this.isMultiPeer && this.multiPeerStreamId === null,
            mode : this.isPlayMode ? 'play' : 'both',
        }));
    }

    public leaveFromRoom(room: string): void {
        console.log('leave request is sent for ' + room);
        (this.webSocketAdaptor as WebSocketAdaptor).send(JSON.stringify({
            command : 'leaveFromRoom',
            room // roomName,
        }));
    }

    public leave(streamId: string): void {
        (this.webSocketAdaptor as WebSocketAdaptor).send(JSON.stringify({
            command : 'leave',
            streamId: this.isMultiPeer && this.multiPeerStreamId !== null ? this.multiPeerStreamId : streamId,
        }));
        this.closePeerConnection(streamId);
        this.multiPeerStreamId = null;
    }

    public getStreamInfo(streamId: string): void {
        (this.webSocketAdaptor as WebSocketAdaptor).send(JSON.stringify({
            command : 'getStreamInfo',
            streamId,
        }));
    }

    public getRoomInfo(roomName: string, streamId: string): void {
        (this.webSocketAdaptor as WebSocketAdaptor).send(JSON.stringify({
            command : 'getRoomInfo',
            streamId,
            room: roomName,
        }));
    }

    public enableTrack(mainTrackId: string, trackId: string, enabled: boolean): void {
        (this.webSocketAdaptor as WebSocketAdaptor).send(JSON.stringify({
            command : 'enableTrack',
            streamId : mainTrackId,
            trackId,
            enabled,
        }));
    }

    public getTracks(streamId: string, token: string): void {
        this.playStreamId.push(streamId);
        (this.webSocketAdaptor as WebSocketAdaptor).send(JSON.stringify(  {
            command : 'getTrackList',
            streamId,
            token,
        }));
    }

    public async gotStream(stream: MediaStream): Promise<void> {
        stream = this.setGainNodeStream(stream);
        this.localStream = stream;
        this.callback.emit({type: 'localStreamChange', value: stream});
        this.checkWebSocketConnection();
        await this.getDevices();
    }

    public async switchDesktopCapture(streamId: string): Promise<void> {
        this.publishMode = PublishMode.SCREEN;
        await this.getUserMedia(this.mediaConstraints, streamId);
    }
    /*
    * This method mixed the first stream audio to the second stream audio and
    * returns mixed stream.
    * stream: Initiali stream that contain video and audio
    *
    */
    public mixAudioStreams(stream: MediaStream, secondStream: MediaStream): MediaStream {
        const composedStream = new MediaStream();
        // added the video stream from the screen
        stream.getVideoTracks().forEach((videoTrack) => {
            composedStream.addTrack(videoTrack);
        });

        this.audioContext = new AudioContext();
        const audioDestination = this.audioContext.createMediaStreamDestination();

        if (stream.getAudioTracks().length > 0) {
            this.soundOriginGainNode = this.audioContext.createGain();

            // Adjust the gain for screen sound
            this.soundOriginGainNode.gain.value = 1;
            const audioSource = this.audioContext.createMediaStreamSource(stream);

            audioSource.connect(this.soundOriginGainNode).connect(audioDestination);
        } else {
            // @ts-ignore
            console.debug('Origin stream does not have audio track');
        }

        if (secondStream.getAudioTracks().length > 0) {
            this.secondStreamGainNode = this.audioContext.createGain();

            // Adjust the gain for second sound
            this.secondStreamGainNode.gain.value = 1;

            const audioSource2 = this.audioContext.createMediaStreamSource(secondStream);
            audioSource2.connect(this.secondStreamGainNode).connect(audioDestination);
        }
        else {
            // @ts-ignore
            console.debug('Second stream does not have audio track');
        }

        audioDestination.stream.getAudioTracks().forEach((track) => {
            composedStream.addTrack(track);
            console.log('audio destination add track');
        });

        return composedStream;
    }

    public setGainNodeStream(stream: MediaStream): MediaStream {

        // Get the videoTracks from the stream.
        const videoTracks = stream.getVideoTracks();

        // Get the audioTracks from the stream.
        const audioTracks = stream.getAudioTracks();

        /**
         * Create a new audio context and build a stream source,
         * stream destination and a gain node. Pass the stream into
         * the mediaStreamSource so we can use it in the Web Audio API.
         */
        this.audioContext = new AudioContext();
        const mediaStreamSource = this.audioContext.createMediaStreamSource(stream);
        const mediaStreamDestination = this.audioContext.createMediaStreamDestination();
        this.soundOriginGainNode = this.audioContext.createGain();

        /**
         * Connect the stream to the gainNode so that all audio
         * passes through the gain and can be controlled by it.
         * Then pass the stream from the gain to the mediaStreamDestination
         * which can pass it back to the RTC client.
         */
        mediaStreamSource.connect(this.soundOriginGainNode);
        this.soundOriginGainNode.connect(mediaStreamDestination);

        this.soundOriginGainNode.gain.value = this.currentVolume !== null ? this.currentVolume : 1;

        /**
         * The mediaStreamDestination.stream outputs a MediaStream object
         * containing a single AudioMediaStreamTrack. Add the video track
         * to the new stream to rejoin the video with the controlled audio.
         */
        const controlledStream = mediaStreamDestination.stream;

        for (const videoTrack of videoTracks) {
            controlledStream.addTrack(videoTrack);
        }
        for (const audioTrack of audioTracks) {
            controlledStream.addTrack(audioTrack);
        }

        /**
         * Use the stream that went through the gainNode. This
         * is the same stream but with altered input volume levels.
         */
        return controlledStream;
    }

    public async switchAudioInputSource(streamId: string): Promise<void> {
        // stop the track because in some android devices need to close the current camera stream
        const audioTrack = (this.localStream as MediaStream).getAudioTracks()[0];
        if (audioTrack) {
            audioTrack.stop();
        }
        else {
            console.warn('There is no audio track in local stream');
        }
        await this.setAudioInputSource(streamId, this.mediaConstraints, null);
    }

    public async switchVideoCameraCapture(streamId: string): Promise<void> {
        // stop the track because in some android devices need to close the current camera stream
        const videoTrack = (this.localStream as MediaStream).getVideoTracks()[0];
        if (videoTrack) {
            videoTrack.stop();
        } else {
            console.warn('There is no video track in local stream');
        }

        this.publishMode = PublishMode.CAMERA;
        await this.setVideoCameraSource(streamId, this.mediaConstraints, null, true);
    }

    public async switchDesktopCaptureWithCamera(streamId: string): Promise<void> {
        this.publishMode = PublishMode.SCREEN_CAMERA;
        await this.getUserMedia(this.mediaConstraints, streamId);
    }

    /**
     * This method updates the local stream. It removes existant audio track from the local stream
     * and add the audio track in `stream` parameter to the local stream
     */
    public updateLocalAudioStream(stream: MediaStream, onEndedCallback: any): void {
        const newAudioTrack = stream.getAudioTracks()[0];

        if (this.localStream !== null && this.localStream.getAudioTracks()[0] !== null) {
            const audioTrack = this.localStream.getAudioTracks()[0];
            this.localStream.removeTrack(audioTrack);
            audioTrack.stop();
            this.localStream.addTrack(newAudioTrack);
        } else if (this.localStream != null){
            this.localStream.addTrack(newAudioTrack);
        } else {
            this.localStream = stream;
        }

        if (onEndedCallback != null) {
            stream.getAudioTracks()[0].onended = (event: Event) => {
                onEndedCallback(event);
            };
        }
    }

    /**
     * This method updates the local stream. It removes existant video track from the local stream
     * and add the video track in `stream` parameter to the local stream
     */
    public updateLocalVideoStream(stream: MediaStream, onEndedCallback: any, stopDesktop: boolean): void {
        if (stopDesktop && this.desktopStream != null) {
            this.desktopStream.getVideoTracks()[0].stop();
        }

        const newVideoTrack = stream.getVideoTracks()[0];

        if (this.localStream != null && this.localStream.getVideoTracks()[0] != null){
            const videoTrack = this.localStream.getVideoTracks()[0];
            this.localStream.removeTrack(videoTrack);
            videoTrack.stop();
            this.localStream.addTrack(newVideoTrack);
        }
        else if (this.localStream != null){
            this.localStream.addTrack(newVideoTrack);
        }
        else{
            this.localStream = stream;
        }

        // @ts-ignore
        this.callback.emit({type: 'localStreamChange', value: this.localStream});
        // this.localVideo?.srcObject = this.localStream;

        if (onEndedCallback != null) {
            stream.getVideoTracks()[0].onended = (event) => {
                onEndedCallback(event);
            };
        }
    }

    /**
     * This method sets Audio Input Source.
     * It calls updateAudioTrack function for the update local audio stream.
     */
    public async setAudioInputSource(streamId: string, mediaConstraints: IMediaConstraints, onEndedCallback: any): Promise<void> {
        const stream = await this.navigatorUserMedia(mediaConstraints, true);
        this.updateAudioTrack(stream as MediaStream, streamId, onEndedCallback);
    }

    /**
     * This method sets Video Input Source.
     * It calls updateVideoTrack function for the update local video stream.
     */
    public async setVideoCameraSource(
        streamId: string,
        mediaConstraints: IMediaConstraints,
        onEndedCallback: any,
        stopDesktop: boolean
    ): Promise<void> {
        let stream = await this.navigatorUserMedia(mediaConstraints, true) as MediaStream;
        stream = this.setGainNodeStream(stream);
        this.updateVideoTrack(stream, streamId, mediaConstraints, onEndedCallback, stopDesktop);
        this.updateAudioTrack(stream, streamId, onEndedCallback);
    }

    public updateAudioTrack(stream: MediaStream, streamId: string, onEndedCallback: any): void {
        if (this.remotePeerConnection[streamId] !== null) {
            this.audioTrackSender = (this.remotePeerConnection[streamId] as RTCPeerConnection).getSenders().find((sender) => {
                return sender.track?.kind === 'audio';
            }) as RTCRtpSender;

            if (this.audioTrackSender) {
                this.audioTrackSender.replaceTrack(stream.getAudioTracks()[0])
                    .then(() => this.updateLocalAudioStream(stream, onEndedCallback))
                    .catch((error) => {
                        console.log(error.name);
                    });
            }
            else {
                console.error('AudioTrackSender is undefined or null');
            }
        }
        else {
            this.updateLocalAudioStream(stream, onEndedCallback);
        }
    }

    public updateVideoTrack(
        stream: MediaStream,
        streamId: string,
        mediaConstraints: IMediaConstraints,
        onEndedCallback: any,
        stopDesktop: any
    ): void {
        if (this.remotePeerConnection[streamId] !== null) {
            this.videoTrackSender = (this.remotePeerConnection[streamId] as RTCPeerConnection).getSenders().find((sender) => {
                return sender.track?.kind === 'video';
            }) as RTCRtpSender;

            if (this.videoTrackSender) {
                this.videoTrackSender.replaceTrack(stream.getVideoTracks()[0]).then(() => {
                    this.updateLocalVideoStream(stream, onEndedCallback, stopDesktop);

                }).catch(error => {
                    console.log(error.name);
                });
            }
            else {
                console.error('VideoTrackSender is undefined or null');
            }
        }
        else {
            this.updateLocalVideoStream(stream, onEndedCallback, stopDesktop);
        }
    }

    public onTrack(event: RTCTrackEvent, streamId: string): void {
        this.callback.emit({type: 'newStreamAvailable', value: {
                stream: event.streams[0],
                track: event.track,
                streamId
            }});
    }

    public iceCandidateReceived(event: RTCPeerConnectionIceEvent, streamId: string): void {
        if (event.candidate) {
            let protocolSupported = false;

            if (event.candidate.candidate === '') {
                // event candidate can be received and its value can be "".
                // don't compare the protocols
                protocolSupported = true;
            } else if (event.candidate.protocol === undefined) {
                this.candidateTypes.forEach(element => {
                    if ((event.candidate as RTCIceCandidate).candidate.toLowerCase().includes(element)) {
                        protocolSupported = true;
                    }
                });
            } else {
                protocolSupported = this.candidateTypes
                    .includes(((event.candidate as RTCIceCandidate).protocol as RTCIceProtocol).toLowerCase());
            }

            if (protocolSupported) {
                if (this.debug) {
                    console.log(`sending ice candiate for stream Id ${streamId}`);
                    console.log(JSON.stringify(event.candidate));
                }
                (this.webSocketAdaptor as WebSocketAdaptor).send(JSON.stringify({
                    command: 'takeCandidate',
                    streamId,
                    label: event.candidate.sdpMLineIndex,
                    id: event.candidate.sdpMid,
                    candidate: event.candidate.candidate
                }));
            }
            else {
                console.log('Candidate\'s protocol(full sdp: '
                    + event.candidate.candidate + ') is not supported. Supported protocols: ' + this.candidateTypes);
                if (event.candidate.candidate !== '') { //
                    this.callbackError.emit({name: 'protocol_not_supported', value: 'Support protocols: ' + this.candidateTypes.toString()
                        + ' candidate: ' + event.candidate.candidate});
                }
            }
        }
        else {
            console.log('No event.candidate in the iceCandidate event');
        }
    }

    public initDataChannel(streamId: string, dataChannel: RTCDataChannel): void {
        dataChannel.onerror = (error) => {
            console.log('Data Channel Error:', error );
            console.log('channel status: ', dataChannel.readyState);
            if (dataChannel.readyState !== 'closed') {
                this.callbackError.emit({name: 'data_channel_error', value: {
                    streamId,
                    error
                }});
            }
        };

        dataChannel.onmessage = (event) => {
            this.callback.emit({type: 'data_received', value: {
                    streamId,
                    event,
                }});
        };

        dataChannel.onopen = () => {
            (this.remotePeerConnection[streamId] as any).dataChannel = dataChannel;
            console.log('Data channel is opened');
            this.callback.emit({type: 'data_channel_opened', value: streamId});
        };
        dataChannel.onclose = () => {
            console.log('Data channel is closed');
            this.callback.emit({type: 'data_channel_closed', value: streamId});
        };
    }

    // data channel mode can be "publish" , "play" or "peer" based on this it is decided which way data channel is created
    public initPeerConnection(streamId: string, dataChannelMode: DataChannelMode): void {
        if (!this.remotePeerConnection[streamId]) {
            this.streamIdForClose = streamId;
            console.log('stream id in init peer connection: ' + streamId + ' close stream id: ' + streamId);
            this.remotePeerConnection[streamId] = new RTCPeerConnection(this.peerConnectionConfig as RTCConfiguration);
            this.remoteDescriptionSet[streamId] = false;
            this.iceCandidateList[streamId] = [];
            if (this.remotePeerConnection[streamId]) {
                if (!this.playStreamId.includes(streamId) && this.localStream !== null) {
                    (this.remotePeerConnection[streamId] as any).addStream(this.localStream);
                }
                (this.remotePeerConnection[streamId] as RTCPeerConnection).onicecandidate = (event) => {
                    this.iceCandidateReceived(event, streamId);
                };
                (this.remotePeerConnection[streamId] as RTCPeerConnection).ontrack = (event) => {
                    this.onTrack(event, streamId);
                };
                if (dataChannelMode === DataChannelMode.PUBLISH) {
                    // open data channel if it's publish mode peer connection
                    const dataChannelOptions = {
                        ordered: true,
                    };
                    const dataChannel = (this.remotePeerConnection[streamId] as RTCPeerConnection)
                        .createDataChannel(streamId, dataChannelOptions);
                    this.initDataChannel(streamId, dataChannel);
                } else if (dataChannelMode === DataChannelMode.PLAY) {
                    // in play mode, server opens the data channel

                    (this.remotePeerConnection[streamId] as RTCPeerConnection).ondatachannel = (event) => {
                        this.initDataChannel(streamId, event.channel);
                    };
                }
                else {
                    // for peer mode do both for now
                    const dataChannelOptions = {
                        ordered: true,
                    };

                    const dataChannelPeer = (this.remotePeerConnection[streamId] as RTCPeerConnection)
                        .createDataChannel(streamId, dataChannelOptions);
                    this.initDataChannel(streamId, dataChannelPeer);

                    (this.remotePeerConnection[streamId] as RTCPeerConnection).ondatachannel = (event) => {
                        this.initDataChannel(streamId, event.channel);
                    };
                }

                (this.remotePeerConnection[streamId] as RTCPeerConnection).oniceconnectionstatechange = () => {
                    this.callback.emit({type: 'ice_connection_state_changed', value: {
                            state: (this.remotePeerConnection[streamId] as RTCPeerConnection).iceConnectionState, streamId}
                    });
                    if (!this.isPlayMode) {
                        if ((this.remotePeerConnection[streamId] as RTCPeerConnection).iceConnectionState === 'connected') {

                            this.changeBandwidth(this.bandwidth, streamId).then(() => {
                                console.log('Bandwidth is changed to ' + this.bandwidth);
                            })
                                .catch(e => console.warn(e));
                        }
                    }
                };
            }
        }
    }

    public closePeerConnection(streamId: string): void {
        if (this.remotePeerConnection[streamId] != null)
        {
            if ((this.remotePeerConnection[streamId] as any).dataChannel) {
                (this.remotePeerConnection[streamId] as any).dataChannel.close();
            }
            if ((this.remotePeerConnection[streamId] as RTCPeerConnection).signalingState !== 'closed') {
                (this.remotePeerConnection[streamId] as RTCPeerConnection).close();
                this.remotePeerConnection[streamId] = null;
                delete this.remotePeerConnection[streamId];
                const playStreamIndex = this.playStreamId.indexOf(streamId);
                if (playStreamIndex !== -1)
                {
                    this.playStreamId.splice(playStreamIndex, 1);
                }
            }
        }

        if (this.remotePeerConnectionStats[streamId] != null)
        {
            clearInterval(this.remotePeerConnectionStats[streamId].timerId as any);
            delete this.remotePeerConnectionStats[streamId];
        }
    }

    public signallingState(streamId: string): RTCSignalingState | null {
        if (this.remotePeerConnection[streamId] != null) {
            return (this.remotePeerConnection[streamId] as RTCPeerConnection).signalingState;
        }
        return null;
    }

    public iceConnectionState(streamId: string): RTCIceConnectionState | null {
        if (this.remotePeerConnection[streamId] !== null) {
            return (this.remotePeerConnection[streamId] as RTCPeerConnection).iceConnectionState;
        }
        return null;
    }

    public async gotDescription(configuration: RTCSessionDescriptionInit, streamId: string): Promise<void> {
        if (this.remotePeerConnection[streamId]) {
            await (this.remotePeerConnection[streamId] as RTCPeerConnection).setLocalDescription(configuration)
                .catch((error) => console.error('Cannot set local description. Error is: ' + error));

            if (this.debug) {
                // @ts-ignore
                console.debug('local sdp: ');
                // @ts-ignore
                console.debug(configuration.sdp);
            }
            (this.webSocketAdaptor as WebSocketAdaptor).send(JSON.stringify({
                command : 'takeConfiguration',
                streamId,
                type : configuration.type,
                sdp : configuration.sdp
            }));
        }
    }

    public turnOffLocalCamera(): void {
        if (this.remotePeerConnection) {
            const track = (this.localStream as MediaStream).getVideoTracks()[0];
            track.enabled = false;
        } else {
            this.callbackError.emit({name: 'NoActiveConnection'});
        }
    }

    public async turnOnLocalCamera(): Promise<void> {
        // If it started in playOnly mode and wants to turn on the camera
        if (this.localStream === null) {
            const stream = await this.navigatorUserMedia(this.mediaConstraints, false);
            await this.gotStream(stream as MediaStream);
        }
        else if (this.remotePeerConnection != null) {
            const track = this.localStream.getVideoTracks()[0];
            track.enabled = true;
        }
    }


    public muteLocalMic(): void {
        if (this.remotePeerConnection != null) {
            const track = (this.localStream as MediaStream).getAudioTracks()[0];
            track.enabled = false;
        }
        else {
            this.callbackError.emit({name: 'NoActiveConnection'});
        }
    }

    /**
     * if there is audio it calls callbackError with "AudioAlreadyActive" parameter
     */
    public unmuteLocalMic(): void {
        if (this.remotePeerConnection != null) {
            const track = (this.localStream as MediaStream).getAudioTracks()[0];
            track.enabled = true;
        }
        else {
            this.callbackError.emit({name: 'NoActiveConnection'});
        }
    }

    public async takeConfiguration(streamId: string, conf: any, type: any): Promise<void> {
        const isTypeOffer = type === 'offer';

        let dataChannelMode = DataChannelMode.PUBLISH;
        if (isTypeOffer) {
            dataChannelMode = DataChannelMode.PLAY;
        }

        this.initPeerConnection(streamId, dataChannelMode);

        const result = await (this.remotePeerConnection[streamId] as RTCPeerConnection).setRemoteDescription(new RTCSessionDescription({
            sdp : conf,
            type
        })).catch((error) => {
            if (this.debug) {
                console.error('set remote description is failed with error: ' + error);
            }
            if (error.toString().indexOf('InvalidAccessError') > -1 || error.toString().indexOf('setRemoteDescription')  > -1){
                /**
                 * This error generally occurs in codec incompatibility.
                 * AMS for a now supports H.264 codec. This error happens when some browsers try to open it from VP8.
                 */
                this.callbackError.emit({name: 'notSetRemoteDescription'});
            }
        });

        if (this.debug) {
            // @ts-ignore
            console.debug('set remote description is successful with response: ' + result + ' for stream : '
                + streamId + ' and type: ' + type);
            // @ts-ignore
            console.debug(conf);
        }

        this.remoteDescriptionSet[streamId] = true;
        const length = this.iceCandidateList[streamId].length;
        // @ts-ignore
        console.debug('Ice candidate list size to be added: ' + length);
        for (let i = 0; i < length; i++) {
            this.addIceCandidate(streamId, this.iceCandidateList[streamId][i]);
        }
        this.iceCandidateList[streamId] = [];

        if (isTypeOffer) {
            // SDP constraints may be different in play mode
            console.log('try to create answer for stream id: ' + streamId);

            const configuration = await (this.remotePeerConnection[streamId] as RTCPeerConnection)
                .createAnswer(this.sdpConstraints as RTCOfferOptions)
                .catch((error) => console.error('create answer error :' + error));
            if (configuration) {
                console.log('created answer for stream id: ' + streamId);
                await this.gotDescription(configuration as RTCSessionDescriptionInit, streamId);
            }
        }
    }

    public takeCandidate(streamId: string, label: number, candidateSdp: string): void {
        const candidate = new RTCIceCandidate({
            sdpMLineIndex : label,
            candidate : candidateSdp
        });

        const dataChannelMode = DataChannelMode.PEER;
        this.initPeerConnection(streamId, dataChannelMode);

        if (this.remoteDescriptionSet[streamId]) {
            this.addIceCandidate(streamId, candidate);
        }
        else {
            // @ts-ignore
            console.debug('Ice candidate is added to list because remote description is not set yet');
            this.iceCandidateList[streamId].push(candidate);
        }
    }

    public addIceCandidate(streamId: string, candidate: RTCIceCandidate): void {
        let protocolSupported = false;
        if (candidate.candidate === '') {
            // candidate can be received and its value can be "".
            // don't compare the protocols
            protocolSupported = true;
        }
        else if (!candidate.protocol) {
            this.candidateTypes.forEach(element => {
                if (candidate.candidate.toLowerCase().includes(element)) {
                    protocolSupported = true;
                }
            });
        }
        else {
            protocolSupported = this.candidateTypes.includes(candidate.protocol.toLowerCase());
        }

        if (protocolSupported)
        {

            (this.remotePeerConnection[streamId] as RTCPeerConnection).addIceCandidate(candidate)
                .then(() => {
                    if (this.debug) {
                        console.log('Candidate is added for stream ' + streamId);
                    }
                })
                .catch((error) => {
                    console.error('ice candiate cannot be added for stream id: ' + streamId + ' error is: ' + error  );
                    console.error(candidate);
                });
        }
        else {
            if (this.debug) {
                console.log('Candidate\'s protocol(' + candidate.protocol + ') is not supported.' +
                    'Candidate: ' + candidate.candidate + ' Supported protocols:' + this.candidateTypes);
            }
        }
    }

    public async startPublishing(streamId: string): Promise<void> {
        this.initPeerConnection(streamId, DataChannelMode.PUBLISH);
        if (this.remotePeerConnection[streamId]) {
            const configuration =
                await (this.remotePeerConnection[streamId] as RTCPeerConnection)
                    .createOffer(this.sdpConstraints as RTCOfferOptions)
                    .catch((error) => {
                        console.error('create offer error for stream id: ' + streamId + ' error: ' + error);
                    });
            await this.gotDescription(configuration as RTCSessionDescriptionInit, streamId);
        }
    }

    /**
     * If we have multiple video tracks in coming versions, this method may cause some issues
     */

    public detectBrowser(): string {
        if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) !== -1 ) {
            return 'Opera';
        } else if (navigator.userAgent.indexOf('Chrome') !== -1 ) {
            return 'chrome';
        } else if (navigator.userAgent.indexOf('Safari') !== -1) {
            return 'safari';
        } else if (navigator.userAgent.indexOf('Firefox') !== -1 ){
            return 'firefox';
        } else {
            return 'Unknown';
        }
    }

    public getVideoSender(streamId: string): RTCRtpSender | null {
        if (this.detectBrowser() === 'chrome' ||
            this.detectBrowser() === 'firefox' ||
            this.detectBrowser() === 'safari' &&
            'RTCRtpSender' in window &&
            'setParameters' in window.RTCRtpSender.prototype)
        {
            if (this.remotePeerConnection[streamId] != null) {
                const senders = (this.remotePeerConnection[streamId] as RTCPeerConnection).getSenders();

                for (const sender of senders) {
                    if (sender.track?.kind === 'video') {
                        return sender;
                    }
                }
            }

        }
        return null;
    }

    /**
     * bandwidth is in kbps+
     */
    public changeBandwidth(bandwidth: number | string, streamId: string): Promise<void> {
        let errorDefinition = '';
        const videoSender = this.getVideoSender(streamId);
        if (videoSender !== null) {
            const parameters = videoSender.getParameters();

            if (!parameters.encodings) {
                parameters.encodings = [{}];
            }

            if (bandwidth === 'unlimited') {
                delete parameters.encodings[0].maxBitrate;
            }
            else {
                parameters.encodings[0].maxBitrate = (bandwidth as number) * 1000;
            }

            return videoSender.setParameters(parameters);
        }
        else {
            errorDefinition = 'Video sender not found to change bandwidth. Streaming may not be active';
        }

        return Promise.reject(errorDefinition);
    }

    public async getStats(streamId: string): Promise<void> {
        // @ts-ignore
        console.log('peerstatsgetstats = ' + this.remotePeerConnectionStats[streamId]);

        const stats = await (this.remotePeerConnection[streamId] as RTCPeerConnection).getStats(null);
        let bytesReceived = -1;
        let videoPacketsLost = -1;
        let audioPacketsLost = -1;
        let fractionLost = -1;
        let currentTime = -1;
        let bytesSent = -1;
        let audioLevel = -1;
        let qlr = '';
        let framesEncoded = -1;
        let width = -1;
        let height = -1;
        let fps = -1;
        let frameWidth = -1;
        let frameHeight = -1;
        let videoRoundTripTime = -1;
        let videoJitter = -1;
        let audioRoundTripTime = -1;
        let audioJitter = -1;
        let framesDecoded = -1;
        let framesDropped = -1;
        let framesReceived = -1;
        let audioJitterAverageDelay = -1;
        let videoJitterAverageDelay = -1;

        stats.forEach(value => {
            switch (value.type) {
                case 'inbound-rtp':
                    if (value.kind !== undefined) {
                        bytesReceived += value.bytesReceived;
                        if (value.kind === 'audio') {
                            audioPacketsLost = value.packetsLost;
                        }
                        if (value.kind === 'video') {
                            videoPacketsLost = value.packetsLost;
                        }
                        fractionLost += value.fractionLost;
                        currentTime = value.timestamp;
                    }
                    break;
                case 'outbound-rtp':
                    // TODO: SPLIT AUDIO AND VIDEO BITRATES
                    bytesSent += value.bytesSent;
                    currentTime = value.timestamp;
                    qlr = value.qualityLimitationReason;
                    if (value.framesEncoded !== null) { // audio tracks are undefined here
                        framesEncoded += value.framesEncoded;
                    }
                    break;
                case 'track':
                    if (value.kind === 'audio') {
                        if (value.audioLevel !== undefined) {
                            audioLevel = value.audioLevel;
                        }
                        if (value.jitterBufferDelay !== undefined && value.jitterBufferEmittedCount !== undefined) {
                            audioJitterAverageDelay = value.jitterBufferDelay / value.jitterBufferEmittedCount;
                        }
                    }
                    if (value.kind === 'video') {
                        if (value.frameWidth !== undefined) {
                            frameWidth = value.frameWidth;
                        }
                        if (value.frameHeight !== undefined) {
                            frameHeight = value.frameHeight;
                        }

                        if (value.framesDecoded !== undefined) {
                            framesDecoded = value.framesDecoded ;
                        }

                        if (value.framesDropped !== undefined) {
                            framesDropped = value.framesDropped;
                        }

                        if (value.framesReceived !== undefined) {
                            framesReceived = value.framesReceived;
                        }

                        if (value.jitterBufferDelay !== undefined && value.jitterBufferEmittedCount !== undefined) {
                            videoJitterAverageDelay = value.jitterBufferDelay / value.jitterBufferEmittedCount;
                        }
                    }
                    break;
                case 'remote-inbound-rtp':
                    if (value.kind !== undefined) {
                        if (value.packetsLost !== undefined) {
                            if (value.kind === 'video') {
                                // this is the packetsLost for publishing
                                videoPacketsLost = value.packetsLost;
                            }
                            if (value.kind === 'audio') {
                                // this is the packetsLost for publishing
                                audioPacketsLost = value.packetsLost;
                            }
                        }
                        if (value.roundTripTime !== undefined) {
                            if (value.kind === 'video') {
                                videoRoundTripTime = value.roundTripTime;
                            }
                            if (value.kind === 'audio') {
                                audioRoundTripTime = value.roundTripTime;
                            }
                        }
                        if (value.jitter !== undefined) {
                            if (value.kind === 'video') {
                                videoJitter = value.jitter;
                            }
                            if (value.kind === 'audio') {
                                audioJitter = value.jitter;
                            }
                        }
                    }
                    break;
                case 'media-source':
                    if (value.kind === 'video') { // returns video source dimensions, not necessarily dimensions being encoded by browser
                        width = value.width;
                        height = value.height;
                        fps = value.framesPerSecond;
                    }
                    break;
            }
        });

        this.remotePeerConnectionStats[streamId].totalBytesReceived = bytesReceived;
        this.remotePeerConnectionStats[streamId].videoPacketsLost = videoPacketsLost;
        this.remotePeerConnectionStats[streamId].audioPacketsLost = audioPacketsLost;
        this.remotePeerConnectionStats[streamId].fractionLost = fractionLost;
        this.remotePeerConnectionStats[streamId].currentTime = currentTime;
        this.remotePeerConnectionStats[streamId].totalBytesSent = bytesSent;
        this.remotePeerConnectionStats[streamId].audioLevel = audioLevel;
        this.remotePeerConnectionStats[streamId].qualityLimitationReason = qlr;
        this.remotePeerConnectionStats[streamId].totalFramesEncoded = framesEncoded;
        this.remotePeerConnectionStats[streamId].resWidth = width;
        this.remotePeerConnectionStats[streamId].resHeight = height;
        this.remotePeerConnectionStats[streamId].srcFps = fps;
        this.remotePeerConnectionStats[streamId].frameWidth = frameWidth;
        this.remotePeerConnectionStats[streamId].frameHeight = frameHeight;
        this.remotePeerConnectionStats[streamId].videoRoundTripTime = videoRoundTripTime;
        this.remotePeerConnectionStats[streamId].videoJitter = videoJitter;
        this.remotePeerConnectionStats[streamId].audioRoundTripTime = audioRoundTripTime;
        this.remotePeerConnectionStats[streamId].audioJitter = audioJitter;
        this.remotePeerConnectionStats[streamId].framesDecoded = framesDecoded;
        this.remotePeerConnectionStats[streamId].framesDropped = framesDropped;
        this.remotePeerConnectionStats[streamId].framesReceived = framesReceived;
        this.remotePeerConnectionStats[streamId].videoJitterAverageDelay = videoJitterAverageDelay;
        this.remotePeerConnectionStats[streamId].audioJitterAverageDelay = audioJitterAverageDelay;

        this.callback.emit({type: 'updated_stats', value: this.remotePeerConnectionStats[streamId]});
    }

    public disableStats(streamId: string): void {
        if (this.remotePeerConnectionStats[streamId].timerId) {
            clearInterval(this.remotePeerConnectionStats[streamId].timerId as any);
            this.remotePeerConnectionStats[streamId].timerId = null;
        }
    }

    public enableStats(streamId: string): void {
        if (this.remotePeerConnectionStats[streamId] === null) {
            this.remotePeerConnectionStats[streamId] = new PeerStats(streamId);
            (this.remotePeerConnectionStats[streamId] as PeerStats).timerId = setInterval(async () =>
                await this.getStats(streamId), 5000);
        }
    }

    /**
     * After calling this function, create new WebRTCAdaptor instance, don't use the the same objectOne
     * Because all streams are closed on server side as well when websocket connection is closed.
     */
    public closeWebSocket(): void {
        for (const key of Object.keys(this.remotePeerConnection)) {
            (this.remotePeerConnection[key] as RTCPeerConnection).close();
        }
        // free the remote peer connection by initializing again
        this.remotePeerConnection = {};
        (this.webSocketAdaptor as WebSocketAdaptor).close();
    }

    public checkWebSocketConnection(): void {
        if (this.webSocketAdaptor === null || !this.webSocketAdaptor?.isConnected()) {
            this.webSocketAdaptor = new WebSocketAdaptor({
                webRTCAdaptor: this,
                callback: this.callback,
                callbackError: this.callbackError,
                debug: this.debug
            });
        }
    }

    public peerMessage(streamId: string, definition: any, data: any): void {
        (this.webSocketAdaptor as WebSocketAdaptor).send(JSON.stringify({
            command : 'peerMessageCommand',
            streamId,
            definition,
            data,
        }));
    }

    public forceStreamQuality(streamId: string, resolution: number): void {
        (this.webSocketAdaptor as WebSocketAdaptor).send(JSON.stringify({
            command : 'forceStreamQuality',
            streamId,
            streamHeight : resolution
        }));
    }

    public sendData(streamId: string, message: string): void {
        const dataChannel = (this.remotePeerConnection[streamId] as any).dataChannel;
        dataChannel.send(message);
    }
}
