import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IChatMessage, IChatListItem } from 'src/app/models/user';

export const loadChatList = createAction(
    '[Chatting] Load Chat List',
    props<{ params: { activeChatId?: string, search?: string } }>()
);

export const loadChatListSuccess = createAction(
    '[Chatting] Load Chat List Success',
    props<{ chatList: IChatListItem[], activeChat: IChatListItem | null }>()
);

export const loadChatListByAgent = createAction(
    '[Chatting] Load Chat List By Agent',
    props<{ params: { activeChatId?: string, search?: string } }>()
);

export const loadChatListSuccessByAgent = createAction(
    '[Chatting] Load Chat List By Agent Success',
    props<{ chatList: IChatListItem[], activeChat: IChatListItem | null }>()
);

export const loadChatListFailure = createAction(
    '[Chatting] Load Chat List Failure',
    props<{ error: HttpErrorResponse }>()
);

export const loadNewMessageCounter = createAction(
    '[Chatting] Load New Message Counter',
    props<{ userId: string }>()
);

export const loadNewMessageCounterSuccess = createAction(
    '[Chatting] Load New Message Counter Success',
    props<{ count: number }>()
);

export const updateNewMessageCounter = createAction(
    '[Chatting] Update New Message Counter',
    props<{ chat: IChatListItem }>()
);

export const clearNewMessageCounter = createAction(
    '[Chatting] Clear New Message Counter',
);

export const clearActiveChat = createAction(
    '[Chatting] Update New Message Counter',
);

export const loadActiveChatMessages = createAction(
    '[Chatting] Load Active Chat Messages',
    props<{ activeChat: IChatListItem }>()
);

export const loadActiveChatMessagesSuccess = createAction(
    '[Chatting] Load Active Chat Messages Success',
    props<{ messages: IChatMessage[], activeChatId: string }>()
);

export const loadActiveChatMessagesFailure = createAction(
    '[Chatting] Load Active Chat Messages Failure',
    props<{ error: HttpErrorResponse }>()
);

export const messageFromChat = createAction(
    '[Chatting] Message From Chat',
    props<{ message: IChatMessage, chat: IChatListItem }>()
);

export const messageWasEdited = createAction(
    '[Chatting] Message Was Edited ',
    props<{ message: IChatMessage, chatId: string }>()
);

export const messageStatusWasChanged = createAction(
    '[Chatting] Message Status Was Changed',
    props<{ messageId: string, chatId: string, messageStatus: 'READ' | 'RECEIVE' }>()
);

export const changeStatusOfPrivateEvent = createAction(
    '[Chatting] Change Status Of Private Event',
    props<{ eventBookingId: string, ation: 'ACCEPT' | 'DECLINE', chatId: string }>()
);

export const changeStatusOfPrivateEventSuccess = createAction(
    '[Chatting] Change Status Of Private Event Success',
    props<{ updatedMessage: IChatMessage, chatId: string }>()
);

export const changeStatusOfPrivateEventFailure = createAction(
    '[Chatting] Change Status Of Private Event Failure',
    props<{ error: HttpErrorResponse }>()
);
