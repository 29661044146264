import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { GlobalLoaderComponent } from './global-loader/global-loader.component';

@NgModule({
    declarations: [GlobalLoaderComponent],
    imports: [CommonModule, MatProgressSpinnerModule],
    exports: [GlobalLoaderComponent],
})
export class GlobalLoaderModule {}
