/* eslint-disable indent */
import { Store } from '@ngrx/store';
import { State } from '../../../store';
import { ICallback, ICallbackError, WebRTCAdaptor } from './web-TRC-adaptor';
import { EventEmitter, Injectable } from '@angular/core';
import { artistStream, userStreams } from '../../../store/current-event/current-event.actions';
import { Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class MediaPublisherService {
    private webRTCAdaptor: WebRTCAdaptor | undefined;
    public callback: EventEmitter<ICallback> = new EventEmitter<ICallback>();
    public callbackError: EventEmitter<ICallbackError> = new EventEmitter<ICallbackError>();
    private roomTimerId: any;
    private publishStreamId = '';
    private token = 'some_token';
    public streamsList: string[] = [];
    public list: Record<string, MediaStream> = {};
    private subscriptions: Subscription[] = [];

    constructor(private store: Store<State>) {}

    public async connectToEvent(eventId: string): Promise<void> {
        this.webRTCAdaptor = new WebRTCAdaptor(
            {
                mediaConstraints: {
                    video: true,
                    audio: true
                },
                peerConnectionConfig: {
                    iceServers: [{
                        urls: 'stun:stun1.l.google.com:19302'
                    }]
                },
                sdpConstraints: {
                    offerToReceiveAudio: false,
                    offerToReceiveVideo: false
                },
                debug: false,
                callback: this.callback,
                callbackError: this.callbackError
            });
        this.subscriptions.push(
            this.callback.subscribe((event: ICallback) => {
                switch (event.type) {
                    case 'initialized':
                        this.webRTCAdaptor?.joinRoom(eventId, `${eventId}__artist`);
                        break;
                    case 'joinedTheRoom':
                        this.publishStreamId = event.value.streamId;
                        this.webRTCAdaptor?.publish(event.value.streamId, this.token);
                        this.roomTimerId = setInterval(() => {
                            this.webRTCAdaptor?.getRoomInfo(eventId, this.publishStreamId);
                        }, 1000);
                        break;
                    case 'localStreamChange':
                        const userStream = event.value?.clone();
                        const track = (userStream as MediaStream)?.getAudioTracks()[0];
                        track.enabled = false;
                        this.store.dispatch(artistStream({artistStream: userStream}));
                        break;
                    case 'newStreamAvailable':
                        this.list[event.value.streamId] = event.value.stream;
                        this.store.dispatch(userStreams({userStreams: Object.assign({}, this.list)}));
                        break;
                    case 'roomInformation':
                        // Checks if any new stream has added, if yes, plays.
                        for (const stream of event.value.streams) {
                            if (!this.streamsList.includes(stream)) {
                                this.webRTCAdaptor?.play(stream, this.token, eventId);
                            }
                        }
                        // Checks if any stream has been removed, if yes, removes the view and stops webrtc connection.
                        for (const stream of this.streamsList) {
                            if (!event.value.streams.includes(stream)) {
                                delete this.list[stream];
                                this.store.dispatch(userStreams({userStreams: Object.assign({}, this.list)}));
                                this.webRTCAdaptor?.stop(stream);
                            }
                        }
                        // Lastly updates the current streamlist with the fetched one.
                        this.streamsList = event.value.streams;
                        break;
                }
            }),
            this.callbackError.subscribe((event: ICallbackError) => {
                let errorMessage = '';
                switch (event.name) {
                    case 'publishTimeoutError':
                        // if (this.roomTimerId !== null) {
                        //     clearInterval(this.roomTimerId);
                        // }
                        break;
                    case 'NotReadableError':
                    case 'TrackStartError':
                        errorMessage = 'Camera or Mic is being used by some other process that does not not allow these devices to be read.';
                        break;
                    case 'OverconstrainedError':
                    case 'ConstraintNotSatisfiedError':
                        errorMessage = 'There is no device found that fits your video and audio constraints. You may change video and audio constraints.';
                        break;
                    case 'NotAllowedError':
                    case 'PermissionDeniedError':
                        errorMessage = 'You are not allowed to access camera and mic.';
                        break;
                    case 'TypeError':
                        errorMessage = 'Video/Audio is required.';
                        break;
                    case 'UnsecureContext':
                        errorMessage = 'Fatal Error: Browser cannot access camera and mic because of unsecure context. Please install SSL and access via https';
                        break;
                    case 'WebSocketNotSupported':
                        errorMessage = 'Fatal Error: WebSocket not supported in this browser';
                        break;
                    case 'no_stream_exist':
                        // TODO: removeRemoteVideo(error.streamId);
                        break;
                    case 'data_channel_error':
                        errorMessage = 'There was a error during data channel communication';
                        break;
                    case 'ScreenSharePermissionDenied':
                        errorMessage = 'You are not allowed to access screen share';
                        break;
                }
                if (errorMessage) {
                    alert(errorMessage);
                }
            })
        );
    }

    public changeCameraState(state: boolean): void {
        state ? this.webRTCAdaptor?.turnOnLocalCamera() : this.webRTCAdaptor?.turnOffLocalCamera();
    }

    public changeMicrophoneState(state: boolean): void {
        state ? this.webRTCAdaptor?.unmuteLocalMic() : this.webRTCAdaptor?.muteLocalMic();
    }

    public unsubscribe(): void {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
        this.subscriptions = [];
        clearInterval(this.roomTimerId);
        this.roomTimerId = null;
        this.webRTCAdaptor?.closeStream();
    }
}
