<div class="info-dialog__wrap">
    <mat-icon
        *ngIf="data?.icon as icon"
        class="info-dialog__icon"
        [svgIcon]="icon.name"
        [style.height.px]="icon.height || '80'"
        [style.width.px]="icon.width || '80'"
    ></mat-icon>
    <h5 [innerHTML]="data?.title"></h5>
    <p  *ngIf="data.message" [innerHTML]="data?.message"></p>
    <rent-simple-button
        *ngIf="data.buttonName"
        mat-dialog-close
        buttonClass="transparent"
        [buttonText]="data.buttonName"
        (action)="onClose()"
    ></rent-simple-button>
</div>
