import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { WEBSITE__LANGUAGES_LIST, USER_LANGUAGES_LIST } from 'src/app/common-ts/constants';
import { IDropdownItem } from 'src/app/models/dropdowns';

@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    public language$$: BehaviorSubject<IDropdownItem[]> = new BehaviorSubject<IDropdownItem[]>(USER_LANGUAGES_LIST);

    getList(type: 'user' | 'app' = 'app'): Observable<IDropdownItem[]> {
        return of(type === 'app' ? WEBSITE__LANGUAGES_LIST : this.language$$.value);
    }
}
