import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { take } from 'rxjs/operators';

@Component({
    selector: 'rent-multi-entities-dropdown',
    templateUrl: './multi-entities-dropdown.component.html',
    styleUrls: ['./multi-entities-dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiEntitiesDropdownComponent implements OnInit, AfterViewInit {

    entityList: any[] = [];
    selectedEntities: any[] = [];

    @Input() activeEntityIds: string[] = [];
    @Input() service: any;
    @Input() placeholder = 'Виберіть';

    @Output() selected = new EventEmitter<string[]>();

    @ViewChild(MatSelect) select?: MatSelect;

    constructor(private cdRef: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.service
            .getList()
            .pipe(take(1))
            .subscribe((entities: any[]) => {
                this.entityList = entities;
                this.setActiveEntity();
            });
    }

    ngAfterViewInit(): void {
        if (this.select) {
            this.select._positions = [
                {
                    originX: 'start',
                    originY: 'bottom',
                    overlayX: 'start',
                    overlayY: 'top',
                },
                {
                    originX: 'start',
                    originY: 'top',
                    overlayX: 'start',
                    overlayY: 'bottom',
                },
            ];
        }
    }

    setActiveEntity(): void {
        this.selectedEntities = this.entityList.filter((entity: any) => this.activeEntityIds.includes(entity.id));
        this.cdRef.markForCheck();
    }

    compareObjects(entity1: any, entity2: any): boolean {
        return entity1?.id === entity2?.id;
    }

    onSelectionChange(changes: MatSelectChange): void {
        const entities = changes.value;
        if (entities) {
            this.selectedEntities = entities;
            const selectedEntityIds = this.selectedEntities.map((entity: any) => entity.id);
            this.selected.emit(selectedEntityIds);
        }
    }
}
