import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { EntitiesDropdownComponent } from './entities-dropdown/entities-dropdown.component';
import { EntitiesInputComponent } from './entities-input/entities-input.component';
import { MultiEntitiesDropdownComponent } from './multi-entities-dropdown/multi-entities-dropdown.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    declarations: [
        EntitiesDropdownComponent,
        EntitiesInputComponent,
        MultiEntitiesDropdownComponent,
    ],
    imports: [CommonModule, ReactiveFormsModule, FormsModule, MatSelectModule, TranslateModule, MatTooltipModule],
    exports: [EntitiesDropdownComponent, EntitiesInputComponent, MultiEntitiesDropdownComponent],
})
export class EntitiesDropdownModule {}
