import { Action, createReducer, on } from '@ngrx/store';
import { IAuthData, IRole, IUser, NotificationToken } from 'src/app/models/user';
import * as AuthActions from './auth.actions';

export const authFeatureKey = 'auth';

export interface State {
    authenticated: boolean;
    token: string | null;
    role: IRole | null;
    currentUser: IUser | null;
    isLoading: boolean;
    errors: any;
    verificationIds: string[];
    isVerificationSent: boolean;
    notification: NotificationToken | null
}

export const initialState: State = {
    authenticated: false,
    token: null,
    role: null,
    currentUser: null,
    isLoading: false,
    errors: null,
    verificationIds: [],
    isVerificationSent: false,
    notification:  null
};

const authReducer = createReducer(
    initialState,
    on(AuthActions.signIn, (state) => ({
        ...state,
        isLoading: true,
        errors: null,
    })),

    on(AuthActions.signInSuccess, (state, { authData }: {authData: Partial<IAuthData>}) => ({
        ...state,
        authenticated: true,
        token: authData?.accessToken || null,
        ...(authData?.user && {role: authData.user?.role} && {currentUser: authData.user}),
        isLoading: false,
        errors: null,
    })),
    on(AuthActions.signInFailure, (state, error) => ({
        ...state,
        isLoading: false,
        errors: error,
    })),
    on(AuthActions.signOut, (state) => ({
        ...state,
        isLoading: true,
        errors: null,
    })),
    on(AuthActions.signOutSuccess, (state) => ({
        ...state,
        authenticated: false,
        token: null,
        role: null,
        currentUser: null,
        isLoading: false,
        errors: null,
        verificationIds: [],
        isVerificationSent: false,
    })),
    on(AuthActions.signOutFailure, (state, error) => ({
        ...state,
        isLoading: false,
        errors: error,
    })),
    on(AuthActions.loadCurrentUser, (state) => ({
        ...state,
        isLoading: true,
        errors: null,
    })),
    on(AuthActions.loadCurrentUserSuccess, (state, { user }) => ({
        ...state,
        currentUser: user,
        role: user?.role || null,
        isLoading: false,
        errors: null,
    })),
    on(AuthActions.loadCurrentUserFailure, (state, error) => ({
        ...state,
        isLoading: false,
        errors: error,
    })),
    on(AuthActions.registration, (state) => ({
        ...state,
        isLoading: true,
        error: null,
    })),
    on(AuthActions.fastRegistration, (state) => ({
        ...state,
        isLoading: true,
        error: null,
    })),
    on(AuthActions.registrationFailure, (state, error) => ({
        ...state,
        isLoading: false,
        errors: error,
    })),
    on(AuthActions.updateCurrentUser, (state) => ({
        ...state,
        isLoading: true,
        error: null,
    })),
    on(AuthActions.updateCurrentUserSuccess, (state, { user }) => ({
        ...state,
        currentUser: {...state.currentUser, ...user},
        isLoading: false,
        error: null,
    })),
    on(AuthActions.updateCurrentUserFailure, (state, error) => ({
        ...state,
        isLoading: false,
        errors: error,
    })),
    on(AuthActions.addVerificationCurrentUser, (state, { fileId }) => ({
        ...state,
        verificationIds: [ ...state.verificationIds, fileId ]
    })),
    on(AuthActions.deleteVerificationCurrentUser, (state, { fileId }) => ({
        ...state,
        verificationIds: state.verificationIds.filter(el => el !== fileId),
    })),
    on(AuthActions.sentVerificationCurrentUser, (state, { isSent }) => ({
        ...state,
        isVerificationSent: isSent,
        ...(!isSent && {verificationIds: []})
    })),

);

export function reducer(state: State | undefined, action: Action) {
    return authReducer(state, action);
}
