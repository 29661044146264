import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { ButtonsModule } from '../shared-modules/button/buttons.module';
import { EntitiesDropdownModule } from '../shared-modules/entities-dropdown/entities-dropdown.module';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { GlobalLoaderModule } from '../shared-modules/global-loader/global-loader.module';
import { BadgeModule } from '../shared-modules/badge/badge.module';
import { NotificationComponent } from './notification/notification.component';
import { ReceiveNotificationsComponent } from './receive-notifications/receive-notifications.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MatIconModule,
        MatMenuModule,
        ButtonsModule,
        EntitiesDropdownModule,
        GlobalLoaderModule,
        BadgeModule,  
        TranslateModule,
        FormsModule,
        MatSelectModule
    ],
    declarations: [
        FooterComponent,
        HeaderComponent,
        BreadcrumbsComponent,
        NotificationComponent,
        ReceiveNotificationsComponent
    ],
    exports: [
        FooterComponent,
        HeaderComponent,
        BreadcrumbsComponent,
        NotificationComponent,
        ReceiveNotificationsComponent
    ],
})
export class SharedModule {}
