import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAuth from './auth.reducer';

export const selectAuthState = createFeatureSelector<fromAuth.State>(fromAuth.authFeatureKey);

export const selectAuthenticated = createSelector(selectAuthState, (state: fromAuth.State) => state.authenticated);

export const selectAuthToken = createSelector(selectAuthState, (state) => state.token);

export const selectCurrentUser = createSelector(selectAuthState, (state) => state.currentUser);

export const selectIsLoading = createSelector(selectAuthState, (state) => state.isLoading);

export const selectErrors = createSelector(selectAuthState, (state) => state.errors);

export const selectVerificationIds = createSelector(selectAuthState, (state) => state.verificationIds);

export const selectIsVerificationSent = createSelector(selectAuthState, (state) => state.isVerificationSent);

