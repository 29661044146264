import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IBreadcrumb } from 'src/app/models/breadcrumbs';

@Component({
    selector: 'rent-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent {
    @Input() withHomePage = true;
    @Input() breadcrumbs: IBreadcrumb[] = [];
    @Input() isDisabled = false;
}
