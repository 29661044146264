import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ISubscriber, IUser } from 'src/app/models/user';
import { ApiService } from '../api/api.service';

@Injectable({
    providedIn: 'root',
})
export class UsersService {
    private readonly url = 'user';
    private readonly favoritesUrl = 'favorites';
    private readonly verificationUrl = 'verification-request';
    private readonly urlForDeactivation = `auth/profile/deactivate`;

    constructor(private apiService: ApiService) {}

    deleteUser(userId: string): Observable<IUser> {
        return this.apiService.deleteRequest(`${this.url}/${userId}`);
    }

    deactiveUser(params: any): Observable<IUser>{
        return this.apiService.patchRequest(`${this.urlForDeactivation}`, params);
    }

    getUser(userId: string): Observable<IUser> {
        return this.apiService.getRequest(`${this.url}/${userId}`).pipe(map(resp => resp?.data));
    }

    getAllArtists(params?: any): Observable<{ result: IUser[], total: number }> {
        return this.apiService.getRequest(`${this.url}/artists`, params).pipe(map(resp => resp?.data));
    }

    getAllAgentsArtists(userId: string, params?: any): Observable<{ result: IUser[], total: number }> {
        return this.apiService.getRequest(`${this.url}/artists?agentId=${userId}`, params).pipe(map(resp => resp?.data));
    }

     getAllVideos(params?: any): Observable<{ result: IUser[], total: number }> {
        return this.apiService.getRequest(`video`, params).pipe(map(resp => resp?.data));
    }

    getAllAgents(params?: any): Observable<{ result: IUser[], total: number }> {
        return this.apiService.getRequest(`${this.url}/agents`, params).pipe(map(resp => resp?.data));
    }

    getUserStats(userId: string): Observable<Partial<IUser> | null> {
        return this.apiService.getRequest(`${this.url}/${userId}/stats`)
            .pipe(
                map(resp => resp?.data),
                catchError(() => of({}))
            );
    }

    getFullUser(userId: string): Observable<IUser> {
        return forkJoin([
            this.getUser(userId),
            this.getUserStats(userId),
        ]).pipe(map(([user, userStats]) => ({...user, ...userStats})));
    }

    addArtistToFavorites(artistId: string, params?: any): Observable<any> {
        return this.apiService.postRequest(`${this.favoritesUrl}/${artistId}`, params);
    }

    removeArtistFromFavorites(artistId: string): Observable<any> {
        return this.apiService.deleteRequest(`${this.favoritesUrl}/${artistId}`);
    }

    getMyFavorites(params?: any): Observable<{ result: IUser[], total: number }> {
        return this.apiService.getRequest(`${this.favoritesUrl}`, params)
            .pipe(
                map(resp => resp?.data),
                map(({result, total}: {result: IUser[], total: number}) => ({
                    result: result?.map((el: IUser) => ({...el, isFavorite: true})) || [],
                    total
                }))
            );
    }

    getSubscribersByArtistId(artistId: string, params?: any): Observable<{ result: ISubscriber[], total: number }> {
        return this.apiService.getRequest(`${this.favoritesUrl}/${artistId}/in-favorites`, params).pipe(map(resp => resp?.data));
    }

    getArtistsByAgentId(agentId: string, params?: any): Observable<{ result: ISubscriber[], total: number }> {
        return this.apiService.getRequest(`${this.favoritesUrl}/${agentId}/in-favorites`, params).pipe(map(resp => resp?.data));
    }

    getGuestSubscriptions(params?: any): Observable<{ result: IUser[], total: number }> {
        return this.apiService.getRequest(this.favoritesUrl, params).pipe(map(resp => resp?.data));
    }

    sendVerificationRequest(fileIds: string[]): Observable<any> {
        return this.apiService.postRequest(this.verificationUrl, { fileIds }).pipe(map(resp => resp?.data));
    }
    getCurrentUser() {
        return this.apiService.getRequest(`${this.url}/me`).pipe(map(resp => resp?.data));
    }
}
