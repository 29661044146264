import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map } from 'rxjs/operators';
import { of } from 'rxjs';

import * as WebsocketActions from './websocket.actions';
import { WebsocketService } from 'src/app/services/websocket/websocket.service';

@Injectable()
export class WebsocketEffects {

    websocketStartConnect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(WebsocketActions.websocketStartConnect),
            concatMap(({token}) => this.websocketService.connected(token)
                .pipe(
                    map((isConnected: boolean) => WebsocketActions.websocketConnectedSuccess({ isConnected })),
                    catchError((error: any) => of(WebsocketActions.websocketConnectedFailure({ error })))
                ))
        );
    });

    websocketDisconnect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(WebsocketActions.websocketDisconnect),
            concatMap(() => this.websocketService.disconnected()
                .pipe(
                    map((isConnected: boolean) => WebsocketActions.websocketConnectedSuccess({ isConnected })),
                    catchError((error: any) => of(WebsocketActions.websocketConnectedFailure({ error })))
                ))
        );
    });

    constructor(
        private actions$: Actions,
        private websocketService: WebsocketService,
    ) {}
}
