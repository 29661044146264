import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'rent-choose-star-row',
    templateUrl: './choose-star-row.component.html',
    styleUrls: ['./choose-star-row.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChooseStarRowComponent {
    pathFullStar = '../../../../../assets/images/png/star-full.png';
    pathEmptyStar = '../../../../../assets/images/png/star-empty.png';
    allStars = new Array(5);
    hoverStars = 0;
    selectedStars = 0;

    @Output() selected = new EventEmitter<number>();

    onSelected(stars: number): void {
        if (stars) {
            this.selectedStars = stars;
            this.selected.emit(stars);
        }
    }

    onMouseenter(event: any): void {
        const ind = event?.target?.tabIndex;
        if (typeof ind === 'number') {
            this.hoverStars = ind + 1;
        }
    }

    onClearHover(): void {
        this.hoverStars = 0;
    }
}
