export const environment = {
    production: false,
    api: `https://api-test.rentalala.com/api/`,
    streaming: `https://s3.eu-north-1.amazonaws.com/rtc.rentalala.com/`,
    websocket: `wss://api-test.rentalala.com/`,
    websocketAdaptor: `wss://rtc.rentalala.com/WebRTCAppEE/websocket`,
    isDisableFunctionality: false,
    locales: ['en', 'he'],
    defaultLocale: 'en',
    isForJew:false,
    payPalCredentials: {
        clientId:
            'AS9XLe0NNQwLawekn0RpwsGkolJLeIpoRsJgf0126zuSGBT9mHy0BvVVZx1AKGHmB9rFaY1FkX-uXr77',
        url: 'https%3A%2F%2Frentalala.com%2Fprofile%2Fpayment',
    },
};

export const firebaseAuthConfig = {
    apiKey: 'AIzaSyC_PUCA3DNHtJaBl837TgOAXl65JVW18OE',
    authDomain: 'test-rentalala.firebaseapp.com',
    projectId: 'test-rentalala',
    storageBucket: 'test-rentalala.appspot.com',
    messagingSenderId: '775710370850',
    appId: '1:775710370850:web:41430e29c00e6735ce6e7b',
    measurementId: 'G-0Y5XXW454K',
};
