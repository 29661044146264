<ng-container *ngIf="!data?.isCloseButtonDisabled">
    <button class="info-dialog__close-btn" (click)="onClose()">
        <mat-icon svgIcon="close"></mat-icon>
    </button>
</ng-container>
<div class="info-dialog__wrap">
    <mat-icon
        *ngIf="data?.icon as icon"
        class="info-dialog__icon"
        [svgIcon]="icon.name"
        [style.height.px]="icon.height || '54'"
        [style.width.px]="icon.width || '54'"
    ></mat-icon>
    <h5 *ngIf="data?.title as title" [innerHTML]="title | translate"></h5>
    <p *ngIf="data?.message as message">
        {{ message | translate }}
        <a class="link" *ngIf="data?.link as link" [href]="link"
            ><span *ngIf="data?.linkTitle as linkTitle">{{ linkTitle }}</span></a
        >
    </p>
</div>
