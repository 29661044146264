import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IFeedback } from 'src/app/models/feedbacks';
import { ApiService } from '../api/api.service';

@Injectable({
    providedIn: 'root',
})
export class FeedbacksService {
    private readonly url = 'feedback';
    
    constructor(private apiService: ApiService) {}

    getList(params?: any): Observable<{ result: IFeedback[], total: number, ppl:number }> {
        return this.apiService.getRequest(this.url, params).pipe(map(res => res.data));
    }

    createFeedback(params?: any): Observable<IFeedback> {
        return this.apiService.postRequest(this.url, params).pipe(map(res => res.data));
    }
}
