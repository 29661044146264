import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as NotificationActions from './notification.actions';
import { map, switchMap } from "rxjs/operators";
import { UsersService } from "src/app/services/users/users.service";



@Injectable()
export class NotificationEffects {

    loadNewNotificationsCount$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(NotificationActions.loadNewNotificationCounter),
            switchMap(({ userId }) => this.usersService.getUserStats(userId)),
            map((user) => NotificationActions.loadNewNotificationCounterSuccess({ newNotificationsCount: user?.newNotificationsCount || 0 }))
        );
    });

    constructor(
        private usersService: UsersService,
        private actions$: Actions,
    ) {}

}
 