import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatIconModule } from '@angular/material/icon';

import { FancyButtonComponent } from './fancy-button/fancy-button.component';
import { SimpleButtonComponent } from './simple-button/simple-button.component';
import { AddButtonComponent } from './add-button/add-button.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { EditButtonComponent } from './edit-button/edit-button.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [
        FancyButtonComponent,
        SimpleButtonComponent,
        AddButtonComponent,
        EditButtonComponent,
    ],
    exports: [FancyButtonComponent, SimpleButtonComponent, AddButtonComponent, EditButtonComponent],
    imports: [CommonModule, MatIconModule, MatProgressSpinnerModule,TranslateModule],
})
export class ButtonsModule {}
