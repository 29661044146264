<button class="events-dialog__close-btn" (click)="onClose()">
    <mat-icon svgIcon="close"></mat-icon>
</button>
<div class="info-dialog__wrap confirm">
    <rent-choose-star-row
        (selected)="onStarsSelected($event)"
    ></rent-choose-star-row>
    <h4>Please, leave a feedback about your agent’s work</h4>

    <textarea
        maxlength="150"
        [(ngModel)]="feedback"
        placeholder="Please tell us"
        [value]="feedback"
    ></textarea>
    <div class="count"><span>{{ ( feedback.length || 0) + '/150' }}</span></div>

    <div class="controls">
        <rent-simple-button
            mat-dialog-close
            buttonClass="mid"
            [buttonText]="'Cancel'"
            (action)="onClose()"
        ></rent-simple-button>
        <rent-simple-button
            buttonClass="light"
            buttonType="submit"
            [buttonText]="'Send'"
            (click)="onSend()"
        ></rent-simple-button>
    </div>
</div>
