import { Action, createReducer, on } from '@ngrx/store';
import { IEvent } from 'src/app/models/events';
import * as EventActions from './current-event.actions';
import { ICurrentEventMessage, IUser } from '../../models/user';
import { removeDuplicates } from 'src/app/common-ts/function-helper';
import { ITip } from 'src/app/models/streaming';

export const currentEventFeatureKey = 'currentEvent';

export interface State {
    currentEvent: Partial<IEvent> | null;
    newEventId: string;
    isLoading: boolean;
    isErrors: boolean;
    isRunning: boolean;
    isReconnecting: boolean;
    isClientReady: boolean;
    isClientConnected: boolean;
    isArtistReady: boolean;
    isArtistConnected: boolean;
    artist: IUser | null;
    client: IUser | null;
    artistStream: MediaStream | null;
    userStreams: Record<string, MediaStream>;
    onlineViewers: Partial<IUser>[];
    chatMessages: ICurrentEventMessage[];
    tips: ITip[];
    isMicrophoneOn: boolean;
    isCameraOn: boolean;
}

export const initialState: State = {
    currentEvent: null,
    newEventId: '',
    isLoading: false,
    isErrors: false,
    isRunning: false,
    isReconnecting: false,
    isClientReady: false,
    isClientConnected: false,
    isArtistReady: false,
    isArtistConnected: false,
    artist: null,
    client: null,
    artistStream: null,
    userStreams: {},
    onlineViewers: [],
    chatMessages: [],
    tips: [],
    isMicrophoneOn: true,
    isCameraOn: true
};
const currentEventReducer = createReducer(
    initialState,
    on(EventActions.loadCurrentEvent, (state) => ({
        ...state,
        isLoading: true,
        isErrors: false,
    })),
    on(EventActions.changeMicrophoneState, (state, microphoneProps) => ({
        ...state,
        isMicrophoneMute: microphoneProps.state,
    })),
    on(EventActions.updateCurrentEventViewersAudio, (state, {userId, isMicrophoneOn}) => ({
        ...state,
        onlineViewers: state.onlineViewers.map((viewer) => {
        return viewer.id === userId ? { ...viewer, isMicrophoneOn } : viewer;
    })
    })),
    on(EventActions.updateLocalMicrophone, (state, { isMicrophoneOn })=> ({
        ...state, 
        isMicrophoneOn
    })),
      on(EventActions.updateLocalCamera, (state, { isCameraOn })=> ({
        ...state, 
        isCameraOn
    })),
    on(EventActions.updateCurrentEventViewersCamera, (state, {userId, isCameraOn}) => ({
        ...state,
        onlineViewers: state.onlineViewers.map((viewer) => {
        return viewer.id === userId ? { ...viewer, isCameraOn } : viewer;
    })
    })),
    on(EventActions.artistStream, (state, { artistStream }) => ({
        ...state,
        artistStream
    })),
    on(EventActions.userStreams, (state, { userStreams }) => ({
        ...state,
        userStreams
    })),
    on(EventActions.loadCurrentEventChatSuccess, (state, { chatMessages }) => ({
        ...state,
        chatMessages
    })),
    on(EventActions.loadCurrentEventViewersSuccess, (state, { onlineViewers }) => ({
        ...state,
        onlineViewers
    })),
    on(EventActions.loadCurrentEventTipsSuccess, (state, { tips }) => ({
        ...state,
        tips
    })),
    on(EventActions.addCurrentEventTip, (state, { tip }) => ({
        ...state,
        tips: [tip, ...state.tips]
    })),
    on(EventActions.currentEventUserWasJoinToChat, (state, { user }) => ({
        ...state,
        ...(user && { onlineViewers: removeDuplicates([
            ...state.onlineViewers,
            user,
        ], 'id').filter(el => el.role === "GUEST")}),
    })),
    on(EventActions.currentEventUserWasLeaveToChat, (state, { user }) => ({
        ...state,
        onlineViewers: state.onlineViewers.filter(el => el.id !== user.id),
    })),
    on(EventActions.currentEventMessageFromChat, (state, { message }) => ({
        ...state,
        chatMessages: [
            ...state.chatMessages,
            message,
        ],
    })),
    on(EventActions.loadCurrentEventSuccess, (state, { event }) => ({
        ...state,
        currentEvent: event,
        isLoading: false,
        isErrors: false,
    })),
    on(EventActions.loadCurrentEventFailure, (state) => ({
        ...state,
        isLoading: false,
        isErrors: true,
    })),
    on(EventActions.updateCurrentEvent, (state) => ({
        ...state,
        isLoading: true,
        isErrors: false,
    })),
    on(EventActions.updateCurrentEventSuccess, (state, { event }) => ({
        ...state,
        currentEvent: {
            ...state.currentEvent,
            ...event,
        },
        newEventId: event.id,
        isLoading: false,
        isErrors: false,
    })),
    on(EventActions.updateCurrentEventFailure, (state) => ({
        ...state,
        isLoading: false,
        isErrors: true,
    })),
    on(EventActions.addInfoToNewEvent, (state, { params }) => ({
        ...state,
        newEventId: '',
        currentEvent: {
            ...state.currentEvent,
            ...params
        }
    })),
    on(EventActions.createNewEvent, (state) => ({
        ...state,
        isErrors: false,
        isLoading: true
    })),
    on(EventActions.createNewEventSuccess, (state, { event }) => ({
        ...state,
        currentEvent: event,
        newEventId: event.id,
        isErrors: false,
        isLoading: false
    })),
    on(EventActions.createNewEvent, (state) => ({
        ...state,
        isErrors: true,
        isLoading: false
    })),
    on(EventActions.clearCurrentEvent, (state) => ({
        ...state,
        newEventId: '',
        currentEvent: null,
        onlineViewers: [],
        chatMessages: [],
        isErrors: false,
        isLoading: false
    })),
    on(EventActions.setArtist, (state, { artist }) => ({
        ...state,
        artist
    })),
    on(EventActions.setClient, (state, { client }) => ({
        ...state,
        client
    })),
    on(EventActions.makeClientReady, (state) => ({
        ...state,
        isClientReady: true
    })),
    on(EventActions.makeArtistReady, (state) => ({
        ...state,
        isArtistReady: true
    })),
    on(EventActions.makeClientUnReady, (state) => ({
        ...state,
        isClientReady: false
    })),
    on(EventActions.makeArtistUnReady, (state) => ({
        ...state,
        isArtistReady: false
    })),
    on(EventActions.makeClientConnected, (state) => ({
        ...state,
        isClientConnected: true
    })),
    on(EventActions.makeArtistConnected, (state) => ({
        ...state,
        isArtistConnected: true
    })),
    on(EventActions.clearEventState, (state) => ({
        ...state,
        activeEvent: null,
        isRunning: false,
        artist: null,
        client: null,
        isClientReady: false,
        isArtistReady: false,
        artistStream: null,
        userStreams: {},
    })),
    on(EventActions.setReconnecting, (state, { isReconnecting }) => ({
        ...state,
        isReconnecting,
    })),
    on(EventActions.setEventRunning, (state, { isRunning }) => ({
        ...state,
        isRunning,
    }))
);

export function reducer(state: State | undefined, action: Action) {
    return currentEventReducer(state, action);
}
