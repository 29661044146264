import { Action, createReducer, on } from '@ngrx/store';
import * as NotificationActions from './notification.actions';

export const notificationFeatureKey = 'notification';

export interface State {
    newNotificationsCount: number;
}
export const initialState: State = {
    newNotificationsCount: 0,
};

const notificationReducer = createReducer(
    initialState,
    on(
        NotificationActions.loadNewNotificationCounterSuccess,
        (state, { newNotificationsCount }) => ({
            ...state,
            newNotificationsCount,
        }),
    ),
    on(NotificationActions.increaseCountNotification, (state) => ({
        ...state,
        newNotificationsCount: state.newNotificationsCount + 1,
    })),
);

export function reducer(state: State | undefined, action: Action) {
    return notificationReducer(state, action);
}
