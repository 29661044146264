import { Action, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { InjectionToken } from '@angular/core';
import { environment } from '../../environments/environment';
import * as fromAuth from './auth/auth.reducer';
import * as fromEvent from './current-event/current-event.reducer';
import * as fromWebsocket from './websocket/websocket.reducer';
import * as fromChatting from './chatting/chatting.reducer';
import * as fromNotification from './notification/notification.reducer';

export interface State {
    [fromAuth.authFeatureKey]: fromAuth.State;
    [fromEvent.currentEventFeatureKey]: fromEvent.State,
    [fromWebsocket.websocketFeatureKey]: fromWebsocket.State,
    [fromChatting.chattingFeatureKey]: fromChatting.State,
    [fromNotification.notificationFeatureKey]: fromNotification.State,

}

export const ROOT_REDUCERS = new InjectionToken<ActionReducerMap<State, Action>>(
    'Root reducers token',
    {
        factory: () => ({
            [fromAuth.authFeatureKey]: fromAuth.reducer,
            [fromEvent.currentEventFeatureKey]: fromEvent.reducer,
            [fromWebsocket.websocketFeatureKey]: fromWebsocket.reducer,
            [fromChatting.chattingFeatureKey]: fromChatting.reducer,
            [fromNotification.notificationFeatureKey]: fromNotification.reducer,
        }),
    },
);

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
