/* eslint-disable indent */
import {ICallback, ICallbackError, WebRTCAdaptor} from './web-TRC-adaptor';
import { EventEmitter } from '@angular/core';
import { environment } from '../../../../../src/environments/environment';

enum WebSocketCommands {
    START = 'start',
    TAKE_CANDIDATE = 'takeCandidate',
    TAKE_CONFIGURATION = 'takeConfiguration',
    STOP = 'stop',
    ERROR = 'error',
    NOTIFICATION = 'notification',
    STREAM_INFORMATION = 'streamInformation',
    ROOM_INFORMATION = 'roomInformation',
    PONG = 'pong',
    TRACK_LIST = 'trackList',
    CONNECT_WITH_NEW_ID = 'connectWithNewId',
    PEER_MESSAGE_COMMAND = 'peerMessageCommand',
    CONNECTED_THE_ROOM = 'connectedTheRoom'
}

interface IWebSocketAdaptorInitialValues {
    webRTCAdaptor: WebRTCAdaptor;
    callback: EventEmitter<ICallback>;
    callbackError: EventEmitter<ICallbackError>;
    debug: boolean;
}

export class WebSocketAdaptor {
    private webSocket: WebSocket;
    private connected = false;
    private pingTimerId: any = null;
    private readonly webRTCAdaptor;
    public callback: EventEmitter<ICallback>;
    public callbackError: EventEmitter<ICallbackError>;
    private readonly debug: boolean;
    // TODO add to env
    private readonly websocketUrl = environment.websocketAdaptor;

    constructor(initialValues: IWebSocketAdaptorInitialValues) {
        this.webRTCAdaptor = initialValues.webRTCAdaptor;
        this.callback = initialValues.callback;
        this.callbackError = initialValues.callbackError;
        this.debug = initialValues.debug;
        this.webSocket = new WebSocket(this.websocketUrl);
        this.connected = false;
        this.pingTimerId = null;
        this.webSocket.onopen = () => {
            this.debugMessage('websocket connected');
            this.pingTimerId = setInterval(() => {
                this.sendPing();
            }, 3000);
            this.connected = true;
            this.callback.emit({type: 'initialized'});
        };
        this.webSocket.onmessage = (event) => {
            const obj = JSON.parse(event.data);
            switch (obj.command) {
                case WebSocketCommands.START:
                    this.debugMessage('received start command');
                    return this.webRTCAdaptor.startPublishing(obj.streamId);
                case WebSocketCommands.TAKE_CANDIDATE:
                    this.debugMessage(`received ice candidate for stream id ${obj.streamId}\n${obj.candidate}`);
                    return this.webRTCAdaptor.takeCandidate(obj.streamId, obj.label, obj.candidate);
                case WebSocketCommands.TAKE_CONFIGURATION:
                    this.debugMessage(`received remote description type for stream id: ${obj.streamId} type: ${obj.type}`);
                    return this.webRTCAdaptor.takeConfiguration(obj.streamId, obj.sdp, obj.type);
                case WebSocketCommands.STOP:
                    this.debugMessage('Stop command received');
                    return this.webRTCAdaptor.closePeerConnection(obj.streamId);
                case WebSocketCommands.ERROR:
                    return this.callbackError.emit({name: obj.definition});
                case WebSocketCommands.NOTIFICATION:
                    this.callback.emit({type: obj.definition, value: obj});
                    if (obj.definition === 'play_finished' || obj.definition === 'publish_finished') {
                        this.webRTCAdaptor.closePeerConnection(obj.streamId);
                    }
                    break;
                case WebSocketCommands.STREAM_INFORMATION:
                case WebSocketCommands.ROOM_INFORMATION:
                case WebSocketCommands.TRACK_LIST:
                case WebSocketCommands.PEER_MESSAGE_COMMAND:
                    return this.callback.emit({type: obj.command, value: obj});
                case WebSocketCommands.PONG:
                    return this.callback.emit({type: obj.command});
                case WebSocketCommands.CONNECT_WITH_NEW_ID:
                    this.webRTCAdaptor.multiPeerStreamId = obj.streamId;
                    this.webRTCAdaptor.join(obj.streamId);
                    break;
                case WebSocketCommands.CONNECTED_THE_ROOM:
                    console.log(WebSocketCommands.CONNECTED_THE_ROOM);
                    console.log(obj);
                    break;
            }
        };

        this.webSocket.onerror = (error) => {
            console.error(`error occured: ${JSON.stringify(error)}`);
            this.clearPingTimer();
            this.callbackError.emit({name: 'webSocket', value: error});
        };

        this.webSocket.onclose = (event) => {
            this.connected = false;
            console.log('connection closed.');
            this.clearPingTimer();
            this.callback.emit({type: 'closed', value: event});
        };
    }

    private debugMessage(message: string): void {
        if (this.debug) {
            console.log(message);
        }
    }

    private clearPingTimer(): void {
        if (this.pingTimerId) {
            this.debugMessage('Clearing ping message timer');
            clearInterval(this.pingTimerId);
            this.pingTimerId = null;
        }
    }

    public sendPing(): void {
        if (this.webSocket) {
            this.webSocket.send(JSON.stringify({command : 'ping'}));
        }
    }

    public close(): void {
        this.webSocket.close();
    }

    public send(text: any): void {
        if (this.webSocket.readyState === 0 || this.webSocket.readyState === 2 || this.webSocket.readyState === 3) {
            this.callbackError.emit({name: 'WebSocketNotConnected'});
            return;
        }
        this.webSocket.send(text);
        this.debugMessage(`sent message: ${text}`);
    }

    public isConnected(): boolean {
        return this.connected;
    }
}
