import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IEvent, IEventType } from 'src/app/models/events';
import { ITip } from 'src/app/models/streaming';
import { ICurrentEventMessage, IRole, IUser } from '../../models/user';

export const loadCurrentEvent = createAction(
    '[Event] Load Current Event',
    props<{ eventId: string, withChat: boolean, invitationToken?: string }>()
);

export const artistStream = createAction(
    '[Event] Load artist stream',
    props<{ artistStream: MediaStream }>()
);

export const userStreams = createAction(
    '[Event] Load users stream',
    props<{ userStreams: Record<string, MediaStream> }>()
);

export const loadCurrentEventSuccess = createAction(
    '[Event] Load Current Event Success',
    props<{ event: IEvent }>()
);

export const loadCurrentEventFailure = createAction(
    '[Event] Load Current Event Failure',
    props<{ error: HttpErrorResponse }>()
);

export const updateCurrentEvent = createAction(
    '[Event] Update Current Event',
);

export const updateCurrentEventSuccess = createAction(
    '[Event] Update Current Event Success',
    props<{ event: IEvent }>()
);

export const updateCurrentEventFailure = createAction(
    '[Event] Update Current Event Failure',
    props<{ error: HttpErrorResponse }>()
);

export const addInfoToNewEvent = createAction(
    '[Event] Add Info To New Event',
    props<{ params: Partial<IEvent> }>()
);

export const createNewEvent = createAction(
    '[Event] Create New Event',
    props<{ isPublic: boolean }>()
);

export const createNewEventSuccess = createAction(
    '[Event] Create New Event Success',
    props<{ event: IEvent }>()
);

export const createNewEventFailure = createAction(
    '[Event] Create New Event Failure',
    props<{ error?: HttpErrorResponse }>()
);

export const clearCurrentEvent = createAction(
    '[Event] Clear Current Event'
);

export const leaveCall = createAction(
    '[Event] Leave call',
    props<{userRole: IRole}>()
);

export const startEvent = createAction(
    '[Event] Start event',
);

export const setEventRunning = createAction(
    '[Event] Set event running',
    props<{ isRunning: boolean }>()
);

export const stopCamera = createAction(
    '[Event] Stop camera',
);

export const becomeReady = createAction(
    '[Event] Become ready',
);

export const setArtist = createAction(
    '[Event] Set artist',
    props<{ artist: IUser | null }>()
);

export const setClient = createAction(
    '[Event] Set client',
    props<{ client: IUser | null }>()
);

export const makeClientReady = createAction(
    '[Event] Make client ready',
);

export const makeArtistReady = createAction(
    '[Event] Make artist ready',
);

export const makeArtistUnReady = createAction(
    '[Event] Make artist unready',
);

export const makeClientUnReady = createAction(
    '[Event] Make client unready',
);

export const makeArtistConnected = createAction(
    '[Event] Make artist connected',
);

export const makeClientConnected = createAction(
    '[Event] Make client connected',
    props<{ isConnected: boolean }>()
);

export const connectToEvent = createAction(
    '[Event] Connect to event',
    props<{ eventId: string, userRole: string, userId: string, eventType: IEventType }>()
);

export const changeCameraState = createAction(
    '[Event] Change camera state',
    props<{ state: boolean , userRole: string}>()
);

export const changeMicrophoneState = createAction(
    '[Event] Change microphone state',
    props<{ state: boolean, userRole: string }>()
);

export const clearEventState = createAction(
    '[Event] Clear event state',
);

export const setReconnecting = createAction(
    '[Event] Set reconnecting',
    props<{ isReconnecting: boolean }>()
);

export const reconnectToEvent = createAction(
    '[Event] Reconnect to event',
    props<{ eventId: string }>()
);

export const eventOperationSuccess = createAction(
    '[Event] Streaming operation is handled'
);

export const loadCurrentEventChat = createAction(
    '[Event] Load Current Event Chat',
    props<{ chatId: string }>()
);

export const loadCurrentEventChatSuccess = createAction(
    '[Event] Load Current Event Chat Success',
    props<{ chatMessages: ICurrentEventMessage[] }>()
);

export const loadCurrentEventChatFailure = createAction(
    '[Event] Load Current Event Chat Failure',
    props<{ error: HttpErrorResponse }>()
);

export const loadCurrentEventViewers = createAction(
    '[Event] Load Current Event Viewers',
    props<{ eventId: string }>()
);

export const updateCurrentEventViewersAudio = createAction(
    '[Event] Update Current Event Viewers Audio',
    props<{ userId: string, isMicrophoneOn: boolean }>()
);

export const updateCurrentEventViewersCamera = createAction(
    '[Event] Update Current Event Viewers Camera ',
    props<{ userId: string, isCameraOn: boolean }>()
);

export const updateLocalMicrophone = createAction(
    '[Event] Update Local Microphone ',
    props<{ isMicrophoneOn: boolean  }>()
);

export const updateLocalCamera = createAction(
    '[Event] Update Local Camera ',
    props<{ isCameraOn: boolean  }>()
);

export const loadCurrentEventViewersSuccess = createAction(
    '[Event] Load Current Event Viewers Success',
    props<{ onlineViewers: Partial<IUser>[] }>()
);

export const loadCurrentEventViewersFailure = createAction(
    '[Event] Load Current Event Viewers Failure',
    props<{ error: HttpErrorResponse }>()
);

export const currentEventMessageFromChat = createAction(
    '[Event] Current Event Message From Chat',
    props<{ message: ICurrentEventMessage }>()
);

export const currentEventUserWasJoinToChat = createAction(
    '[Event] Current Event User Was Join To Chat',
    props<{ user: Partial<IUser> }>()
);

export const currentEventUserWasLeaveToChat = createAction(
    '[Event] Current Event User Was Leave To Chat',
    props<{ user: Partial<IUser> }>()
);

export const loadCurrentEventTips = createAction(
    '[Event] Load Current Event Tips',
    props<{ eventId: string }>()
);

export const loadCurrentEventTipsSuccess = createAction(
    '[Event] Load Current Event Tips Success',
    props<{ tips: ITip[] }>()
);

export const loadCurrentEventTipsFailure = createAction(
    '[Event] Load Current Event Tips Failure',
    props<{ error: HttpErrorResponse }>()
);

export const addCurrentEventTip = createAction(
    '[Event] Add Current Event Tip',
    props<{ tip: ITip }>()
);
