import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'rent-simple-button',
    templateUrl: './simple-button.component.html',
    styleUrls: ['./simple-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleButtonComponent {
    @Input() isDisabled = false;
    @Input() buttonText = '';
    @Input() buttonType = 'button';
    @Input() buttonClass: 'light' | 'light pale' | 'mid' | 'dark' | 'transparent' = 'dark';
    @Input() isLoading = false;

    @Output() action = new EventEmitter<void>();

    onAction(): void {
        if (!this.isDisabled) {
            this.action.emit();
        }
    }
}
