import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IAuthData, ICredentials, IUser } from 'src/app/models/user';

export const signIn = createAction(
    '[Auth] Sign user in',
    props<{ credentials: ICredentials, invitationToken?: string, eventId?: string }>()
);

export const signInSuccess = createAction(
    '[Auth] User successfully signed in',
    props<{ authData: Partial<IAuthData> }>()
);

export const signInFailure = createAction(
    '[Auth] Failed to sign in user',
    props<{ error: HttpErrorResponse }>()
);

export const registration = createAction(
    '[Auth] Registration new user',
    props<{ credentials: ICredentials, invitationToken?: string, eventId?: string }>()
);

export const fastRegistration = createAction(
    '[Auth] Fast Registration new user',
    props<{ credentials: any, eventId?: string }>()
);

export const registrationFailure = createAction(
    '[Auth] Failed to registration new user',
    props<{ error: HttpErrorResponse }>()
);

export const signOut = createAction(
    '[Auth] Sign out',
    props<{ withRedirect: boolean }>()
);


export const signOutSuccess = createAction(
    '[Auth] User successfully signed out'
);

export const signOutFailure = createAction(
    '[Auth] Failed to sign out user',
    props<{ error: HttpErrorResponse }>()
);

export const loadCurrentUser = createAction(
    '[Auth] Load Current User'
);

export const loadCurrentUserSuccess = createAction(
    '[Auth] Load Current User Success',
    props<{ user: IUser }>()
);

export const loadCurrentUserFailure = createAction(
    '[Auth] Load Current User Failure',
    props<{ error: HttpErrorResponse }>()
);

export const updateCurrentUser = createAction(
    '[Auth] Update Current User',
    props<{ params: Partial<IUser> }>()
);

export const updateCurrentUserSuccess = createAction(
    '[Auth] Update Current User Success',
    props<{ user: IUser }>()
);

export const updateCurrentUserFailure = createAction(
    '[Auth] Update Current User Failure',
    props<{ error: HttpErrorResponse }>()
);

export const addVerificationCurrentUser = createAction(
    '[Auth] Added Verification Current User',
    props<{ fileId: string }>()
);

export const deleteVerificationCurrentUser = createAction(
    '[Auth] Deleted Verification Current User',
    props<{ fileId: string }>()
);

export const sentVerificationCurrentUser = createAction(
    '[Auth] Sent Verification Current User',
    props<{ isSent: boolean }>()
);
