import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'rent-global-loader',
    templateUrl: './global-loader.component.html',
    styleUrls: ['./global-loader.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalLoaderComponent {
    @Input() isBackground = false;
}
