<div class="multi-entities-dropdown__wrap">
    <mat-select
        multiple
        panelClass="multi-entities-dropdown"
        [placeholder]="placeholder"
        [disableRipple]="true"
        [disableOptionCentering]="true"
        [compareWith]="compareObjects"
        [(value)]="selectedEntities"
        (selectionChange)="onSelectionChange($event)"
    >
        <mat-select-trigger>
            {{ selectedEntities.length ? selectedEntities[0].title : '' }}
            <span *ngIf="selectedEntities.length > 1">{{ '+' + (selectedEntities.length - 1) }}</span>
      </mat-select-trigger>
        <mat-option [value]="option" *ngFor="let option of entityList">{{ option.title }}</mat-option>
    </mat-select>
</div>
