import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { INotification } from 'src/app/models/notification';
import { FeedbackAboutAgentComponent } from '../../shared-modules/dialogs/feedback-about-agent/feedback-about-agent.component';

@Component({
    selector: 'rent-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
    @Input() notification: INotification | undefined;
    @Output() delete = new EventEmitter<any>();
    svgType = '';
    color = '';
    buttonText = '';
    routerLink = '';
    type = '';

    constructor(private matDialog: MatDialog) {}

    ngOnInit(): void {
        this.setValues();
    }

    setValues() {
        switch (this.notification?.type) {
            case 'PAYMENT_REFUND':
                this.svgType = 'checkmark-single';
                break;

            case 'EVENT_BOOKING_ACCEPTED':
                this.svgType = 'checkmark-single';
                break;

            case 'EVENT_REMINDER_15_MINUTES':
                this.svgType = 'play';
                this.buttonText = 'Go to event';
                this.routerLink = '/artist/event/';
                break;
            case 'EVENT_REMINDER_1_HOUR':
                this.svgType = 'play';
                break;

            case 'EVENT_CANCELLED_BY_ARTIST':
            case 'PAYMENT_ARTIST_DEACTIVATED':
            case 'VERIFICATION_DECLINED':
            case 'COLLABORATION_AGENT_DEACTIVATED':
            case 'COLLABORATION_ARTIST_DEACTIVATED':
                this.svgType = 'cancel';
                this.color = 'red';
                break;

            case 'VERIFICATION_ACCEPTED':
                this.svgType = 'checkmark-type2';
                this.color = 'green';
                break;

            case 'COLLABORATION_FEEDBACK':
                this.svgType = 'agent';
                this.buttonText = 'Leave a feedback';
                this.routerLink = '';
                break;

            case 'PROMOTION_EXPIRE_1_HOUR':
                this.svgType = 'calendar';
                break;

            default:
                console.log('Invalid type');
        }
    }

    onDelete(id: any) {
        this.delete.emit(id);
    }
    openModal(id: any) {
        this.matDialog
            .open(FeedbackAboutAgentComponent, {
                width: '571px',
                backdropClass: 'backdrop__dark',
                panelClass: 'info-dialog',
                data: {
                    id,
                },
            })
            .afterClosed();
    }
}
