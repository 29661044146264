import * as moment from 'moment';
import csc from 'country-state-city';
import { Moment } from 'moment';
import { ICategoryGenre, ICategoryType } from '../models/categories';
import { IEvent } from '../models/events';
import { IUser } from '../models/user';

export function getFileSize(size: number | string): string {
    const s = Number(size);
    return (
        (s >= 1073741824 && (s / 1073741824).toFixed(2) + ' GB') ||
        (s >= 1048576 && (s / 1048576).toFixed(2) + ' MB') ||
        (s >= 1024 && (s / 1024).toFixed(2) + ' KB') ||
        (s > 1 && s + ' bytes') ||
        (s === 1 && s + ' byte') ||
        '0 byte'
    );
}

export function getRandomCode(numberOfLength: number): string {
    return Math.random()
        .toString(36)
        .substring(13 - numberOfLength)
        .toUpperCase();
}

export function stringifyTime(givenDate: Moment, isInReverseOrder = false): string {
    const dateNow: Moment = moment();
    const duration = moment.duration(
        isInReverseOrder ? givenDate.diff(dateNow) : dateNow.diff(givenDate),
        'milliseconds',
    );
    const totalSeconds = duration.asSeconds();
    const totalMinutes = duration.asMinutes();
    const totalHours = duration.asHours();

    const minutes = Math.floor(totalMinutes % 60);
    const seconds = Math.floor(totalSeconds % 60);
    const hours = Math.floor(totalHours);
    const h = `${hours < 10 ? '0' + hours : hours}:`;
    const m = `${minutes < 10 ? '0' + minutes : minutes}:`;
    const s = `${seconds < 10 ? '0' + seconds : seconds}`;

    return h + m + s;
}

export function secondsToHms(duration: number, fullFormat = false) {
    const h = Math.floor(duration / 3600);
    const m = Math.floor((duration % 3600) / 60);
    const s = Math.floor((duration % 3600) % 60);
    let hDisplay, mDisplay, sDisplay;

    if (fullFormat) {
        hDisplay = h > 0 ? h + (h === 1 ? ' hour, ' : ' hours, ') : '';
        mDisplay = m > 0 ? m + (m === 1 ? ' minute, ' : ' minutes, ') : '';
        sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : '';
    } else {
        hDisplay = ((h > 9 && h) || (h > 0 && `0${h}`) || '00') + ':';
        mDisplay = ((m > 9 && m) || (m > 0 && `0${m}`) || '00') + ':';
        sDisplay = (s > 9 && s) || (s > 0 && `0${s}`) || '00';
    }
    return hDisplay + mDisplay + sDisplay;
}

export function getCategoryType(genres: ICategoryGenre[]): ICategoryType[] {
    if (genres) {
        let allTypes: ICategoryType[] = [];
        genres.forEach((el) => {
            const typeName = el.type;
            const ind = allTypes.findIndex(
                (el) => el.title.toLowerCase() === typeName.toLowerCase(),
            ); // .toLowerCase() for a while until BE fixed

            if (ind !== -1) {
                allTypes[ind].genres.push(el);
            } else {
                allTypes = [
                    ...allTypes,
                    {
                        title: typeName,
                        genres: [el],
                        count:0,
                    },
                ];
            }
        });
        return allTypes;
    } else {
        return [];
    }
}

export function getTags(entity: Partial<IEvent> | IEvent | IUser): ICategoryGenre[] {
    return entity ? [...(entity.standardTags || []), ...(entity.customTags || [])] : [];
}

export function getTimeZone(user: Partial<IUser> | IUser | null): string | number {
    const userOffset = user?.timezoneCode;
    const localeOffset = moment().utcOffset();
    return userOffset ? userOffset.slice(3) : localeOffset;
}

export function getUserDate(user: Partial<IUser> | IUser | null, date?: string | null): Moment {
    return date ? moment(date).utcOffset(getTimeZone(user)) : moment();
}

export function getUserDateToSting(
    user: Partial<IUser> | IUser | null,
    date?: string | null,
): string {
    return getUserDate(user, date).format('YYYY-MM-DDTHH:mm:ss');
}

export function sortBy(
    array: any[],
    key: string,
    direction: 'asc' | 'desc' = 'asc',
    isDateSort = false,
): any[] {
    if (Array.isArray(array)) {
        if (isDateSort) {
            const ASC_SORT = (a: any, b: any) =>
                new Date(a[key]).getTime() - new Date(b[key]).getTime();
            const DESC_SORT = (a: any, b: any) =>
                new Date(b[key]).getTime() - new Date(a[key]).getTime();

            return array ? [...array].sort(direction === 'asc' ? ASC_SORT : DESC_SORT) : [];
        }

        return direction === 'asc'
            ? array.sort((a: any, b: any) => (a[key] > b[key] ? 1 : -1))
            : array.sort((a: any, b: any) => (a[key] < b[key] ? 1 : -1));
    }

    return [];
}

export function removeDuplicates(array: any[], prop = ''): any[] {
    return array.filter(
        (obj, pos, arr) => arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos,
    );
}

export function getLocalTimezone(): string {
    return String(new Date().getTimezoneOffset());
}

export function getGmtFormat(): string {
    const d = new Date().toString().match(/([A-Z]+[\+-][0-9]+)/);
    const gmt = d && d[1];
    return gmt ? gmt.slice(0, -2) + ':' + gmt.slice(-2) : 'GMT+00:00';
}

export function getLocalTimeDuration(dateStart?: string | null): string {
    if (dateStart) {
        const start = moment(dateStart).local().format('HH');
        return `${start}:00-${start}:45 (${getGmtFormat()})`;
    }
    return '';
}

export function getFileIconType(type: string): string {
    switch (type) {
        case 'video/mp4':
        case 'video/mpeg':
        case 'video/webm':
            return 'type-video';
        case 'image/jpeg':
        case 'image/jpg':
        case 'image/png':
            return 'type-img';
        case 'application/pdf':
        case 'application/msword':
        default:
            return 'type-doc';
    }
}

export function getCountry(countryCode = ''): string {
    return countryCode ? csc.getCountryByCode(countryCode)?.name : ' - ';
}

export function getAge(birth = ''): number {
    return birth ? moment().diff(moment(birth), 'years') : 0;
}

export function mobileCheck(): boolean {
    let check = false;

    (function (a) {
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                a,
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                a.substr(0, 4),
            )
        ) {
            check = true;
        }
    })(navigator.userAgent || navigator.vendor);

    return check;
}
