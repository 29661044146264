import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IInfoDialogData } from 'src/app/models/dialogs';

@Component({
    selector: 'rent-info-dialog',
    templateUrl: './info-dialog.component.html',
    styleUrls: ['./info-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoDialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: IInfoDialogData,
        private matDialogRef: MatDialogRef<InfoDialogComponent>,
    ) {}

    onClose(): void {
        this.matDialogRef.close();
    }
}
