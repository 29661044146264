import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IConfirmDialogData } from 'src/app/models/dialogs';

@Component({
    selector: 'rent-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: IConfirmDialogData,
        private matDialogRef: MatDialogRef<ConfirmDialogComponent>
    ) {}

    onClose(state = false): void {
        this.matDialogRef.close(state);
    }
}
