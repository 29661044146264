<div class="notification-container" #dialog>
    <ng-container *ngIf="count">
        <p class="close" (click)="onClose()">Close all</p>
        <div class="notification-wrapper">
            <div class="list" *ngFor="let notification of notificationList">
                <rent-notification [notification]="notification" (delete)="onDelete($event)"></rent-notification>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="count===0">
        <div class="no-notification">
            <div class="icon-close">
                <mat-icon svgIcon="close" (click)="onClose()"></mat-icon>
            </div>
            <div class="icon">
                <mat-icon svgIcon="alert"></mat-icon>
            </div>
            <p class="description">You haven't any notifications!</p>
        </div>
    </ng-container>
</div>