import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'rent-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit {
    imgNum = 1;

    @Input() isResponsive = false;
    @Input() isDisabled = false;

    ngOnInit(): void {
        this.imgNum = this.getRandomNumber(1, 5);
    }

    getRandomNumber(min: number, max: number): number {
        const r = Math.random()*(max-min) + min;
        return Math.floor(r);
    }
}
