import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { State } from 'src/app/store';
import { selectCurrentUser } from 'src/app/store/auth/auth.selectors';

@Injectable({
    providedIn: 'root',
})
export class AdminGuard implements CanActivate {
    constructor(private store: Store<State>, private router: Router) {}

    canLoad(): Observable<boolean | UrlTree> {
        return this.checkPermissions();
    }

    canActivate(): Observable<boolean | UrlTree> {
        return this.checkPermissions();
    }

    checkPermissions(): Observable<boolean | UrlTree> {
        return this.store.pipe(select(selectCurrentUser)).pipe(
            filter(user => !!user),
            map((user) => {
                const role = user?.role;

                if (role !== 'ADMIN' && role !== 'SUPER_ADMIN') {
                    return this.router.createUrlTree(['/']);
                }
                return true;
            }),
            take(1),
        );
    }
}
