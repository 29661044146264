import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromChatting from './chatting.reducer';

export const selectChattingState = createFeatureSelector<fromChatting.State>(fromChatting.chattingFeatureKey);

export const selectNewMessagesCount = createSelector(selectChattingState, (state) => state.newMessagesCount);

export const selectActiveChat = createSelector(selectChattingState, (state) => state.activeChat);

export const selectActiveChatMessages = createSelector(selectChattingState, (state) => state.activeChatMessages);

export const selectChatList = createSelector(selectChattingState, (state) => state.chatList);

export const selectIsChatsLoading = createSelector(selectChattingState, (state) => state.isLoading);
