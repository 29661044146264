<div
    [class]="'entities-dropdown__wrap ' + dropdownClass"
    [ngClass]="{'with-border': isBorder, disabled: isDisabled, 'with-background': isBackground, 'with-hover': isHover}"
>
    <mat-select
        [panelClass]="'entities-dropdown ' + dropdownClass"
        [placeholder]="placeholder"
        [disableRipple]="true"
        [disabled]="isDisabled"
        [disableOptionCentering]="isDisabledCentering"
        [compareWith]="compareObjects"
        [(value)]="selectedEntity"
        (selectionChange)="onSelectionChange($event)"
        (click)="onPrevValue(selectedEntity)"
    >
        <mat-option
            *ngFor="let option of entityList"
            [value]="option"
            [disabled]="option.disabled || option.selected"
            [matTooltip]="withToolTip ? option.title: '' "
            matTooltipClass="light-tooltip multi-line"
            matTooltipPosition="above"
            >{{ option.title || option.id | translate}}
        </mat-option>
    </mat-select>
</div>
