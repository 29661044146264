import { Action, createReducer, on } from '@ngrx/store';
import { IChatListItem, IChatMessage } from 'src/app/models/user';
import * as ChattingActions from './chatting.actions';

export const chattingFeatureKey = 'chatting';

export interface State {
    activeChat: IChatListItem | null;
    chatList: IChatListItem[];
    activeChatMessages: IChatMessage[];
    newMessagesCount: number;
    isLoading: boolean;
    isErrors: boolean;
}

export const initialState: State = {
    activeChat: null,
    chatList: [],
    activeChatMessages: [],
    newMessagesCount: 0,
    isLoading: false,
    isErrors: false,
};

const chattingReducer = createReducer(
    initialState,
    on(ChattingActions.loadChatList, (state) => ({
        ...state,
        activeChatMessages: [],
        isLoading: true,
        isErrors: false,
    })),
    on(ChattingActions.loadNewMessageCounterSuccess, (state, { count }) => ({
        ...state,
        newMessagesCount: count
    })),
    on(ChattingActions.clearNewMessageCounter, (state) => ({
        ...state,
        newMessagesCount: 0
    })),
    on(ChattingActions.clearActiveChat, (state) => ({
        ...state,
        activeChat: null,
        chatList: [],
        activeChatMessages: [],
        isLoading: false,
        isErrors: false,
    })),
    on(ChattingActions.loadChatListSuccess, (state, { chatList, activeChat }) => ({
        ...state,
        chatList,
        activeChat: activeChat
            ? {
                ...activeChat,
                newMessagesCount: 0
            }
            : null,
        isLoading: false,
        isErrors: false,
    })),
    on(ChattingActions.loadChatListFailure, (state) => ({
        ...state,
        isLoading: false,
        isErrors: true,
    })),
    on(ChattingActions.loadActiveChatMessages, (state, { activeChat }) => {
        return {
            ...state,
            activeChat,
            activeChatMessages: [],
            isLoading: true,
            isErrors: false,
        };
    }),
    on(ChattingActions.loadActiveChatMessagesSuccess, (state, { messages, activeChatId }) => {
        const chatList = state.chatList;
        return {
            ...state,
            chatList: chatList.map(el => el.id === activeChatId ? ({...el, newMessagesCount: 0}) : el),
            activeChatMessages: messages,
            isLoading: false,
            isErrors: false,
        };
    }),
    on(ChattingActions.loadActiveChatMessagesFailure, (state) => ({
        ...state,
        isLoading: false,
        isErrors: true,
    })),
    on(ChattingActions.messageFromChat, (state, { message, chat }) => {
        const updatedChatId = chat?.id || '';
        const isNewMessageFromActiveChat = updatedChatId === state.activeChat?.id;
        const updatedChats = [
            isNewMessageFromActiveChat ? {...chat, newMessagesCount: 0} : chat,
            ...state.chatList.filter(chat => chat.id !== updatedChatId) || [],
        ];
        const messages = state.activeChatMessages;
        return {
            ...state,
            chatList: updatedChats,
            activeChatMessages: isNewMessageFromActiveChat ? [...messages, message] : messages,
        };
    }),
    on(ChattingActions.messageWasEdited, (state, { message, chatId }) => {
        const { activeChat, activeChatMessages = [] } = state;
        const isEditedMessageFromActiveChat = chatId === activeChat?.id;
        const messages = isEditedMessageFromActiveChat
            ? activeChatMessages.map(el => el.id === message.id ? message : el)
            : activeChatMessages;
        return {
            ...state,
            activeChatMessages: messages,
        };
    }),
    on(ChattingActions.messageStatusWasChanged, (state, { messageId, chatId, messageStatus }) => {
        const { activeChat, activeChatMessages = [] } = state;
        const isEditedMessageFromActiveChat = chatId === activeChat?.id;
        const messages = isEditedMessageFromActiveChat
            ? activeChatMessages.map(el => ({...el, messageStatus: 'READ'} as IChatMessage))
            : activeChatMessages;
        return {
            ...state,
            activeChatMessages: messages,
        };
    }),
    on(ChattingActions.changeStatusOfPrivateEventSuccess, (state, { updatedMessage, chatId }) => {
        const { activeChat, activeChatMessages = [] } = state;
        const isEditedMessageFromActiveChat = chatId === activeChat?.id;
        const messages = isEditedMessageFromActiveChat
            ? activeChatMessages.map(el => el.eventBookingId === updatedMessage.id
                ? ({
                    ...el,
                    requestStatus: updatedMessage.status,
                    eventId: updatedMessage.eventId
                })
                : el)
            : activeChatMessages;
        return {
            ...state,
            activeChatMessages: messages,
        };
    }),
);

export function reducer(state: State | undefined, action: Action) {
    return chattingReducer(state, action);
}
