import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AdminGuard } from './guards/admin/admin.guard';
import { AuthGuard } from './guards/auth/auth.guard';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./modules/landing/landing.module').then((m) => m.LandingModule),
    },
    {
        path: 'auth',
        loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
    },
    {
        path: 'profile',
        loadChildren: () => import('./modules/edit-profile/profile.module').then((m) => m.ProfileModule),
        canLoad: [AuthGuard],
    },
    {
        path: 'artist',
        loadChildren: () => import('./modules/artist/artist.module').then((m) => m.ArtistModule),
    },
    {
        path: 'guest',
        loadChildren: () => import('./modules/guest/guest.module').then((m) => m.GuestModule),
    },
    {
        path: 'agent',
        loadChildren: () => import('./modules/agent/agent.module').then((m) => m.AgentModule),
    },
    {
        path: 'admin',
        loadChildren: () => import('./modules/admin/admin.module').then((m) => m.AdminModule),
        canLoad: [AuthGuard, AdminGuard],
    },
    {
        path: 'stream',
        loadChildren: () => import('./modules/streaming/streaming.module').then((m) => m.StreamingModule),
        canLoad: [AuthGuard],
    },
    {
        path: 'chat',
        loadChildren: () => import('./modules/chatting/chatting.module').then((m) => m.ChattingModule),
        canLoad: [AuthGuard],
    },
    {
        path: '**',
        component: PageNotFoundComponent,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        onSameUrlNavigation: 'reload',
        scrollPositionRestoration: 'enabled',
        preloadingStrategy: PreloadAllModules,
    })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
