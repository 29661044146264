import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, Subscription, SubscriptionLike } from 'rxjs';
import * as io from 'socket.io-client';
import { IEvent } from 'src/app/models/events';
import { ITip } from 'src/app/models/streaming';
import { IChatMessage, IChatListItem, ICurrentEventMessage, IUser } from 'src/app/models/user';
import { State } from 'src/app/store';
import {
    loadNewMessageCounterSuccess,
    messageFromChat,
    messageWasEdited,
    messageStatusWasChanged,
} from 'src/app/store/chatting/chatting.actions';
import {
    addCurrentEventTip,
    changeCameraState,
    changeMicrophoneState,
    currentEventMessageFromChat,
    currentEventUserWasJoinToChat,
    currentEventUserWasLeaveToChat,
    updateCurrentEventSuccess,
    updateCurrentEventViewersAudio,
    updateCurrentEventViewersCamera,
    updateLocalCamera,
    updateLocalMicrophone,
} from 'src/app/store/current-event/current-event.actions';
import {
    websocketConnectedFailure,
    websocketConnectedSuccess,
} from 'src/app/store/websocket/websocket.actions';
import { environment } from 'src/environments/environment';
import { selectCurrentUser } from 'src/app/store/auth/auth.selectors';

@Injectable({
    providedIn: 'root',
})
export class WebsocketService implements OnDestroy {
    private websocket?: SocketIOClient.Socket;
    userSubscription: SubscriptionLike = Subscription.EMPTY;

    constructor(private store: Store<State>) {}

    connected(authToken: string): Observable<boolean> {
        this.websocket = io(environment.websocket, {
            secure: true,
            transports: ['websocket', 'polling'],
            query: { authToken },
        });

        this.websocket.on('EVENT_WAS_CHANGED', ({ event }: { event: IEvent }) => {
            this.store.dispatch(updateCurrentEventSuccess({ event }));
        });
        this.websocket.on('EVENT_WAS_PROLONG', (event: IEvent) => {
            this.store.dispatch(updateCurrentEventSuccess({ event }));
        });

        this.websocket.on(
            'MESSAGE_FROM_EVENT_CHAT',
            ({ message }: { message: ICurrentEventMessage }) => {
                this.store.dispatch(currentEventMessageFromChat({ message }));
            },
        );

        this.websocket.on('USER_STREAM_STATE_WAS_CHANGED', (data: any) => {
            if (data.isMicrophoneOn !== undefined) {
                this.store.dispatch(
                    updateCurrentEventViewersAudio({
                        userId: data.user?.id as any,
                        isMicrophoneOn: data.isMicrophoneOn,
                    }),
                );
            }
            if (data.isCameraOn !== undefined) {
                this.store.dispatch(
                    updateCurrentEventViewersCamera({
                        userId: data.user?.id as any,
                        isCameraOn: data.isCameraOn,
                    }),
                );
            }

            this.userSubscription = this.store.select(selectCurrentUser).subscribe((user) => {
                if (user?.id === data.user?.id) {
                    if (data.isMicrophoneOn !== undefined) {
                        this.store.dispatch(
                            changeMicrophoneState({
                                state: data?.isMicrophoneOn as any,
                                userRole: 'GUEST',
                            }),
                        );
                        this.store.dispatch(
                            updateLocalMicrophone({ isMicrophoneOn: data?.isMicrophoneOn }),
                        );
                    }

                    if (data.isCameraOn !== undefined) {
                        this.store.dispatch(
                            changeCameraState({
                                state: data?.isCameraOn as any,
                                userRole: 'GUEST',
                            }),
                        );
                        this.store.dispatch(updateLocalCamera({ isCameraOn: data?.isCameraOn }));
                    }
                }
            });
        });

        this.websocket.on('USER_WAS_JOIN_TO_EVENT', ({ user }: { user: Partial<IUser> }) => {
            this.store.dispatch(currentEventUserWasJoinToChat({ user }));
        });

        this.websocket.on('USER_WAS_LEAVE_FROM_EVENT', ({ user }: { user: Partial<IUser> }) => {
            this.store.dispatch(currentEventUserWasLeaveToChat({ user }));
        });

        this.websocket.on(
            'MESSAGE_FROM_CHAT',
            ({ message, chat }: { message: IChatMessage; chat: IChatListItem }) => {
                this.store.dispatch(messageFromChat({ message, chat }));
            },
        );

        this.websocket.on('TIP_WAS_DONATED', (danate: ITip) => {
            this.store.dispatch(addCurrentEventTip({ tip: danate }));
        });

        this.websocket.on(
            'MESSAGE_WAS_EDITED',
            ({ message, chatId }: { message: IChatMessage; chatId: string }) => {
                this.store.dispatch(messageWasEdited({ message, chatId }));
            },
        );

        this.websocket.on(
            'MESSAGE_WAS_READ',
            ({ chatId, messageId }: { messageId: string; chatId: string }) => {
                this.store.dispatch(
                    messageStatusWasChanged({ messageId, chatId, messageStatus: 'READ' }),
                );
            },
        );

        this.websocket.on(
            'ALL_NEW_MESSAGES_COUNT_CHANGED',
            ({ allNewMessagesCount }: { allNewMessagesCount: number }) => {
                this.store.dispatch(loadNewMessageCounterSuccess({ count: allNewMessagesCount }));
            },
        );

        this.websocket.on(
            'MESSAGE_WAS_RECEIVED',
            ({ chatId, messageId }: { messageId: string; chatId: string }) => {
                this.store.dispatch(
                    messageStatusWasChanged({ messageId, chatId, messageStatus: 'RECEIVE' }),
                );
            },
        );

        this.websocket.on('INVALID_DATA', (res: any) => {
            console.log('INVALID_DATA ', res);
        });

        this.websocket.on('connect', () => {
            this.store.dispatch(websocketConnectedSuccess({ isConnected: true }));
        });

        this.websocket.on('reconnect', (reason: string) => {
            this.store.dispatch(websocketConnectedFailure({ error: reason }));
        });

        this.websocket.on('disconnect', (reason: string) => {
            this.store.dispatch(websocketConnectedFailure({ error: reason }));
            if (reason === 'io server disconnect') {
                this.websocket?.connect();
            }
        });

        return of(true);
    }

    ngOnDestroy() {
        this.userSubscription.unsubscribe();
    }

    disconnected(): Observable<boolean> {
        this.websocket?.disconnect();
        return of(false);
    }

    emit(eventName: string, data: object): void {
        this.websocket?.emit(eventName, data);
    }

    subscribe(eventName: string, handler: (...args: any[]) => any): void {
        this.websocket?.off(eventName, handler);
        this.websocket?.on(eventName, handler);
    }
}
