import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'rent-fancy-button',
    templateUrl: './fancy-button.component.html',
    styleUrls: ['./fancy-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FancyButtonComponent {
    @Input() buttonText = '';
}
