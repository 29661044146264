<header class="header__wrap">
    <ng-container *ngIf="!currentUser; else accountHeader">
        <div class="header__wrap-info">
            <a class="logo" routerLink="/"><img src="../../../../assets/images/new-logo.svg" alt="logo" /></a>
        </div>
        <div class="header__wrap-info">
            <a
                class="register"
                routerLink="/auth/artist-preview"
                routerLinkActive="active"
            >
                <p>{{'header.artist' | translate}}</p>
            </a>
            <a
                class="register"
                [ngClass]="{disabled: env.isDisableFunctionality}"
                routerLink="/auth/agent-preview"
                routerLinkActive="active"
            >
                <p>{{'header.agent' | translate}}</p>
            </a>
            <ng-container *ngTemplateOutlet="dropdownsTemplate"></ng-container>
            <a routerLink="/auth/login">
                <mat-icon svgIcon="person"></mat-icon>
                <p>{{'header.logIn' | translate}}</p>
            </a>
            <a routerLink="/auth/register">
            <rent-simple-button
                buttonClass="light"
                buttonType="submit"
                buttonText="{{'Sign up'| translate}}"
            ></rent-simple-button>
        </a>
        </div>
    </ng-container>
    <ng-template #accountHeader>
        <a class="logo" routerLink="/" [ngClass]="{'disabled': env.isDisableFunctionality }"><img src="../../../../assets/images/new-logo.svg" alt="logo"/></a>
        <div class="header__wrap-info">
            <div class="header__wrap-info">
                <a *ngIf="role === 'ARTIST'" routerLink="/agents" [ngClass]="{'disabled': env.isDisableFunctionality}">
                    <p class="all">{{'header.agents' | translate}}</p>
                </a>
                <ng-container *ngTemplateOutlet="dropdownsTemplate" ></ng-container>
                <button [matMenuTriggerFor]="menu" class="name">
                    <mat-icon svgIcon="person"></mat-icon>
                    <span>{{ currentUser?.stageName || currentUser?.name }}</span>
                    <mat-icon svgIcon="arrow-down"></mat-icon>
                </button>
                <mat-menu #menu="matMenu" class="header-menu">
                    <button class="item" *ngIf="isAdmin" (click)="onNavigateToProfile()">Admin System</button>
                    <button class="item"  *ngIf="!isAdmin" (click)="onNavigateToProfile()">{{'My profile' | translate }}</button>
                    <button class="item" (click)="onSignOut()">{{"Log out" | translate}}</button>
                </mat-menu>
                <a
                    *ngIf="role === 'ARTIST'"
                    class="item"
                    [routerLink]="'/artist/' + currentUser?.id + '/calendar'" [ngClass]="{'disabled': env.isDisableFunctionality}"
                >
                    <mat-icon svgIcon="calendar"></mat-icon>
                </a>
                <a class="item" (click)="showNotification()" [ngClass]="{'disabled': env.isDisableFunctionality}"><mat-icon svgIcon="alarm"></mat-icon>
                  <rent-badge [count]="newNotificationsCount$  | async"></rent-badge></a>
                <a class="item" routerLink="/chat" [ngClass]="{'disabled': env.isDisableFunctionality}">
                    <mat-icon svgIcon="message"></mat-icon>
                    <rent-badge [count]="newMessagesCount$ | async"></rent-badge>
                </a>
            </div>
        </div>
    </ng-template>
</header>

<ng-template #dropdownsTemplate>
    <div class="header__wrap-info" [ngClass]="{'disabled': env.isDisableFunctionality}">
        <rent-entities-dropdown
            *ngIf="currentUser"
            class="header__currency-dropdown"
            [isHover]="true"
            [isBorder]="false"
            [isBackground]="false"
            [withFirstOption]="true"
            [service]="currencyService"
            (selected)="onChangedCurrency($event)"
        ></rent-entities-dropdown>
         <button [matMenuTriggerFor]="language" class="name">
            <span class="language">{{localLanguageService.defaultLanguage$ | async}}</span>
            <mat-icon svgIcon="arrow-down"></mat-icon></button>
        <mat-menu #language="matMenu" class="header-menu language">
            <button
                [ngClass]="{'disabled': env.isDisableFunctionality}"
                [ngClass]="{'disabled': localLanguageService.defaultLanguage$.value==='he'}"
                class="item"
                (click)="useLanguage('en')"
            >En</button>
            <button
                [disabled]="localLanguageService.defaultLanguage$.value==='en'"
                [ngClass]="{'disabled': localLanguageService.defaultLanguage$.value==='en'}"
                class="item"
                (click)="useLanguage('he')">He</button>
        </mat-menu>
    </div>
</ng-template>
<rent-global-loader [isBackground]="true" *ngIf="isRouting"></rent-global-loader>
