import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocalLanguageService {

 public defaultLanguage$ = new BehaviorSubject<string>(environment.defaultLocale);
  constructor() { }
}
