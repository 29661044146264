import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { AuthService } from './services/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { LocalLanguageService } from './services/local-language/local-language.service';
import { mobileCheck } from './common-ts/function-helper';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialogComponent } from './shared/shared-modules/dialogs/info-dialog/info-dialog.component';
interface IAppIcon {
    name: string;
    src: string;
}
@Component({
    selector: 'rent-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    private appIcons: IAppIcon[] = [
        { name: 'bug', src: '../assets/icons/icon-bug.svg' },
        { name: 'tips', src: '../assets/icons/icon-tips.svg' },
        { name: 'tips-type2', src: '../assets/icons/icon-tips-type2.svg' },
        { name: 'star', src: '../assets/icons/icon-star.svg' },
        { name: 'empty-star', src: '../assets/icons/icon-empty-star.svg' },
        { name: 'instagram', src: '../assets/icons/icon-instagram.svg' },
        { name: 'facebook', src: '../assets/icons/icon-facebook.svg' },
        { name: 'google', src: '../assets/icons/icon-google.svg' },
        { name: 'linkedin', src: '../assets/icons/icon-linkedin.svg' },
        { name: 'twitter', src: '../assets/icons/icon-twitter.svg' },
        { name: 'tik-tok', src: '../assets/icons/icon-tik-tok.svg' },
        { name: 'youtube', src: '../assets/icons/icon-youtube.svg' },
        { name: 'arrow-down', src: '../assets/icons/icon-arrow-down.svg' },
        { name: 'arrow-down-long', src: '../assets/icons/icon-arrow-down-long.svg' },
        { name: 'person', src: '../assets/icons/icon-person.svg' },
        { name: 'person-type2', src: '../assets/icons/icon-person-type2.svg' },
        { name: 'people', src: '../assets/icons/icon-people.svg' },
        { name: 'play-button', src: '../assets/icons/icon-play-button.svg' },
        { name: 'home', src: '../assets/icons/icon-home.svg' },
        { name: 'dot', src: '../assets/icons/icon-dot.svg' },
        { name: 'share', src: '../assets/icons/icon-share.svg' },
        { name: 'share-circle', src: '../assets/icons/icon-share-circle.svg' },
        { name: 'alert', src: '../assets/icons/icon-alert.svg' },
        { name: 'close', src: '../assets/icons/icon-close.svg' },
        { name: 'heard-empty', src: '../assets/icons/icon-heard-empty.svg' },
        { name: 'heard-full', src: '../assets/icons/icon-heard-full.svg' },
        { name: 'right-angle-bracket', src: '../assets/icons/icon-right-angle-bracket.svg' },
        { name: 'eye', src: '../assets/icons/icon-eye.svg' },
        { name: 'no-eye', src: '../assets/icons/icon-no-eye.svg' },
        { name: 'checkmark-single', src: '../assets/icons/icon-checkmark-single.svg' },
        { name: 'checkmark-double', src: '../assets/icons/icon-checkmark-double.svg' },
        { name: 'checkmark-small', src: '../assets/icons/icon-checkmark-small.svg' },
        { name: 'checkmark-big', src: '../assets/icons/icon-checkmark-big.svg' },
        { name: 'checkmark-type2', src: '../assets/icons/icon-checkmark-type2.svg' },
        { name: 'checkbox-selected', src: '../assets/icons/icon-checkbox-selected.svg' },
        { name: 'checkbox-unselected', src: '../assets/icons/icon-checkbox-unselected.svg' },
        { name: 'flag-en', src: '../assets/icons/icon-flag-en.svg' },
        { name: 'flag-ru', src: '../assets/icons/icon-flag-ru.svg' },
        { name: 'flag-he', src: '../assets/icons/icon-flag-he.svg' },
        { name: 'flag-de', src: '../assets/icons/icon-flag-de.svg' },
        { name: 'flag-ar', src: '../assets/icons/icon-flag-ar.svg' },
        { name: 'flag-bn', src: '../assets/icons/icon-flag-bn.svg' },
        { name: 'flag-es', src: '../assets/icons/icon-flag-es.svg' },
        { name: 'flag-it', src: '../assets/icons/icon-flag-it.svg' },
        { name: 'flag-vi', src: '../assets/icons/icon-flag-vi.svg' },
        { name: 'flag-fa', src: '../assets/icons/icon-flag-fa.svg' },
        { name: 'flag-fr', src: '../assets/icons/icon-flag-fr.svg' },
        { name: 'flag-hi', src: '../assets/icons/icon-flag-hi.svg' },
        { name: 'flag-zh', src: '../assets/icons/icon-flag-zh.svg' },
        { name: 'flag-ja', src: '../assets/icons/icon-flag-ja.svg' },
        { name: 'flag-ko', src: '../assets/icons/icon-flag-ko.svg' },
        { name: 'flag-pl', src: '../assets/icons/icon-flag-pl.svg' },
        { name: 'flag-pt', src: '../assets/icons/icon-flag-pt.svg' },
        { name: 'flag-ro', src: '../assets/icons/icon-flag-ro.svg' },
        { name: 'flag-ta', src: '../assets/icons/icon-flag-ta.svg' },
        { name: 'flag-tr', src: '../assets/icons/icon-flag-tr.svg' },
        { name: 'avatar-big', src: '../assets/icons/icon-avatar-big.svg' },
        { name: 'download', src: '../assets/icons/icon-download.svg' },
        { name: 'download-type2', src: '../assets/icons/icon-download-type2.svg' },
        { name: 'calendar', src: '../assets/icons/icon-calendar.svg' },
        { name: 'bankcard-ae', src: '../assets/icons/icon-bankcard-ae.svg' },
        { name: 'bankcard-visa', src: '../assets/icons/icon-bankcard-visa.svg' },
        { name: 'bankcard-maestro', src: '../assets/icons/icon-bankcard-maestro.svg' },
        { name: 'bankcard-paypal', src: '../assets/icons/icon-bankcard-paypal.svg' },
        { name: 'bankcard-mastercard', src: '../assets/icons/icon-bankcard-mastercard.svg' },
        { name: 'plus-circle', src: '../assets/icons/icon-plus-circle.svg' },
        { name: 'delete', src: '../assets/icons/icon-delete.svg' },
        { name: 'edit', src: '../assets/icons/icon-edit.svg' },
        { name: 'card', src: '../assets/icons/icon-card.svg' },
        { name: 'search', src: '../assets/icons/icon-search.svg' },
        { name: 'play', src: '../assets/icons/icon-play.svg' },
        { name: 'message', src: '../assets/icons/icon-message.svg' },
        { name: 'alarm', src: '../assets/icons/icon-alarm.svg' },
        { name: 'type-img', src: '../assets/icons/icon-type-img.svg' },
        { name: 'type-video', src: '../assets/icons/icon-type-video.svg' },
        { name: 'type-doc', src: '../assets/icons/icon-type-doc.svg' },
        { name: 'drop-file', src: '../assets/icons/icon-drop-file.svg' },
        { name: 'reload', src: '../assets/icons/icon-reload.svg' },
        { name: 'statistic', src: '../assets/icons/icon-statistic.svg' },
        { name: 'contract', src: '../assets/icons/icon-contract.svg' },
        { name: 'statistic-type2', src: '../assets/icons/icon-statistic-type2.svg' },
        { name: 'gift', src: '../assets/icons/icon-gift.svg' },
        { name: 'ticket', src: '../assets/icons/icon-ticket.svg' },
        { name: 'clock', src: '../assets/icons/icon-clock.svg' },
        { name: 'radiobutton-unchecked', src: '../assets/icons/icon-radiobutton-unchecked.svg' },
        { name: 'radiobutton-checked', src: '../assets/icons/icon-radiobutton-checked.svg' },
        { name: 'info', src: '../assets/icons/icon-info.svg' },
        { name: 'audio-mute', src: '../assets/icons/icon-audio-mute.svg' },
        { name: 'audio-unmute', src: '../assets/icons/icon-audio-unmute.svg' },
        { name: 'video-mute', src: '../assets/icons/icon-video-mute.svg' },
        { name: 'video-unmute', src: '../assets/icons/icon-video-unmute.svg' },
        { name: 'minus', src: '../assets/icons/icon-minus.svg' },
        { name: 'smiley', src: '../assets/icons/icon-smiley.svg' },
        { name: 'letter', src: '../assets/icons/icon-letter.svg' },
        { name: 'letter-type2', src: '../assets/icons/icon-letter-type2.svg' },
        { name: 'chat', src: '../assets/icons/icon-chat.svg' },
        { name: 'chat-type2', src: '../assets/icons/icon-chat-type2.svg' },
        { name: 'full-screen', src: '../assets/icons/icon-full-screen.svg' },
        { name: 'normal-screen', src: '../assets/icons/icon-normal-screen.svg' },
        { name: 'cancel', src: '../assets/icons/icon-cancel.svg' },
        { name: 'attachment', src: '../assets/icons/icon-attachment.svg' },
        { name: 'settings', src: '../assets/icons/icon-settings.svg' },
        { name: 'agent', src: '../assets/icons/icon-agent.svg' },
        { name: 'exit', src: '../assets/icons/icon-exit.svg' },
        { name: 'verification', src: '../assets/icons/icon-verification.svg' },
        { name: 'ads-contracts', src: '../assets/icons/icon-ads-contracts.svg' },
        { name: 'promotion', src: '../assets/icons/icon-promotion.svg' },
        { name: 'deactivated-person', src: '../assets/icons/icon-deactivated-person.svg' },
        { name: 'verification', src: '../assets/icons/icon-verification.svg' },
        { name: 'double-right-arrow', src: '../assets/icons/icon-double-right-arrow.svg' },
        { name: 'unions', src: '../assets/icons/icon-unions.svg' },
        { name: 'sad-smile', src: '../assets/icons/icon-sad-smile.svg' },
        { name: 'attach-file', src: '../assets/icons/icon-text-file.svg' },
        { name: 'user', src: '../assets/icons/icon-user.svg' }
    ];

    constructor(
        private authService: AuthService,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private translateService: TranslateService,
        public localLanguageService: LocalLanguageService,
        private matDialog: MatDialog,
    ) {}

    ngOnInit(): void {
        this.registerIcons(this.appIcons);
        this.authService.checkUserData();
        this.translateService.use(this.localLanguageService.defaultLanguage$.value);
        this.setAttribute();
        this.mobileCheck();
    }

    setAttribute() {
        if (
            this.localLanguageService.defaultLanguage$.value !== 'he' &&
            document.getElementsByTagName('body')[0].hasAttribute('dir')
        ) {
            document.getElementsByTagName('body')[0].removeAttribute('dir');
        } else if (
            this.localLanguageService.defaultLanguage$.value === 'he' &&
            !document.getElementsByTagName('body')[0].hasAttribute('dir')
        ) {
            document.getElementsByTagName('body')[0].setAttribute('dir', 'rtl');
        }
    }

    mobileCheck(): void {
        try {
            if (mobileCheck()) {
                this.matDialog.open(InfoDialogComponent, {
                    width: '571px',
                    backdropClass: 'backdrop__dark',
                    panelClass: 'info-dialog',
                    disableClose: true,
                    data: {
                        icon: {
                            name: 'sad-smile',
                        },
                        title: 'This website  does not support mobile devices yet.',
                        isCloseButtonDisabled: true,
                    },
                });
            }
        } catch (err) {
            console.error('AppComponent - something went wrong.');
        }
    }

    private registerIcons(icons: IAppIcon[]): void {
        icons.forEach((icon) => {
            this.matIconRegistry.addSvgIcon(
                icon.name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(icon.src),
            );
        });
    }
}
