<footer
    [ngClass]="{'responsive': isResponsive}"
    [style.background-image]="'url(../../../../assets/images/footer/footer-' + imgNum + '.jpg)'"
>
    <div class="footer__wrap" [ngClass]="{'disabled': isDisabled}">
        <div class="footer__info">
            <div class="footer__group">
                <img
                    class="logo"
                    src="../../../../assets/images/logo.png"
                    alt="logo"
                />
                <nav class="footer__group-nav">
                    <ul class="company">
                        <li class="headline">{{'footer.about' | translate}}</li>
                        <li><a routerLink="/">{{'footer.careers' | translate}}</a></li>
                        <li><a routerLink="/">{{'footer.news' | translate}}</a></li>
                        <li><a routerLink="/">{{'footer.partnerships' | translate}}</a></li>
                        <li>
                            <a routerLink="/">{{'footer.property' | translate}}</a>
                        </li>
                        <li><a routerLink="/">{{'footer.investor' | translate}}</a></li>
                    </ul>
                    <ul class="company">
                        <li class="headline">{{'footer.communities' | translate}}</li>
                        <li><a routerLink="/">{{'footer.artist' | translate}}</a></li>
                        <li><a routerLink="/">{{'footer.guest' | translate}}</a></li>
                        <li><a routerLink="/">{{'footer.agent' | translate}}</a></li>
                        <li><a routerLink="/">{{'footer.client' | translate}}</a></li>
                        <li><a routerLink="/">{{'footer.standart' | translate}}</a></li>
                        <li><a routerLink="/">{{'footer.invite' | translate}}</a></li>
                    </ul>
                </nav>
            </div>
            <div class="footer__group-nav">
                <ul class="company">
                    <li class="headline">{{'footer.support' | translate}}</li>
                    <li><a routerLink="/">{{'footer.help' | translate}}</a></li>
                    <li><a routerLink="/">{{'footer.safety' | translate}}</a></li>
                    <li><a routerLink="/">{{'footer.FAQ' | translate}}</a></li>
                     <li><a href="mailto:customers@spectaculum.live">{{'footer.ContactUs' | translate}}</a></li>
                    <li class="headline advertise">{{'footer.advertise' | translate}}</li>
                </ul>
                <ul>
                    <li class="headline">{{'footer.more' | translate}}</li>
                    <li><a routerLink="/">{{'footer.guides' | translate}}</a></li>
                    <li><a routerLink="/">{{'footer.inspired' | translate}}</a></li>
                    <li class="headline join">{{'footer.join' | translate}}</li>
                    <li>
                        <ul class="social">
                            <li>
                                <a href="https://www.instagram.com/the_spectaculum/" target="_blank"
                                    ><mat-icon svgIcon="instagram"></mat-icon
                                ></a>
                            </li>
                            <li>
                                <a href="https://www.facebook.com/SpectaculumIL" target="_blank"
                                    ><mat-icon svgIcon="facebook"></mat-icon
                                ></a>
                            </li>
                            <li>
                                <a href="https://twitter.com/THE_SPECTACULUM" target="_blank"
                                    ><mat-icon svgIcon="twitter"></mat-icon
                                ></a>
                            </li>
                            <li>
                                <a href="https://www.tiktok.com/@the_spectaculum?lang=en" target="_blank"
                                    ><mat-icon svgIcon="tik-tok"></mat-icon
                                ></a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
        <div class="footer__footnotes">
            <div class="footer__footnotes-info">

                <a  href="../../../../assets/documents/Privacy_Policy.pdf" target="_blank" rel="noopener noreferrer"

                    ><p>{{'footer.policy' | translate}}</p></a
                >
                <a href="../../../../assets/documents/Terms_Conditions.pdf" target="_blank" rel="noopener noreferrer"
                    ><p>{{'footer.terms' | translate}}</p></a
                >
            </div>
        </div>
    </div>
</footer>
