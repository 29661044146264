export class PeerStats {
    public totalBytesReceivedCount = 0;
    public videoPacketsLost = 0;
    public fractionLost = 0;
    public startTime = 0;
    public lastFramesEncoded = 0;
    public totalFramesEncodedCount = 0;
    public lastBytesReceived = 0;
    public lastBytesSent = 0;
    public currentTimestamp = 0;
    public lastTime = 0;
    public timerId: any | null = null;
    public firstByteSentCount = 0;
    public firstBytesReceivedCount = 0;
    public audioLevel = -1;
    public qualityLimitationReason = '';
    // res width and res height are video source resolutions
    public resWidth = 0;
    public resHeight = 0;
    public srcFps = 0;
    // frameWidth and frameHeight are the resolutions of the sent video
    public frameWidth = 0;
    public frameHeight = 0;
    public videoRoundTripTime = 0;
    public videoJitter = 0;
    public audioRoundTripTime = 0;
    public audioJitter = 0;
    public audioPacketsLost = 0;
    public framesReceived = 0;
    public framesDropped = 0;
    public framesDecoded = 0;
    public audioJitterAverageDelay = 0;
    public videoJitterAverageDelay = 0;
    public streamId;
    public totalBytesSentCount = 0;

    constructor(streamId: string) {
        this.streamId = streamId;
        this.totalBytesSent = 0;
    }

    // kbits/sec
    public get averageOutgoingBitrate(): number {
        return Math.floor(8 * (this.totalBytesSentCount - this.firstByteSentCount) / (this.currentTimestamp - this.startTime));
    }

    // frames per second
    get currentFPS(): string {
        return (((this.totalFramesEncodedCount - this.lastFramesEncoded) / (this.currentTimestamp - this.lastTime)) * 1000).toFixed(1);
    }
    // kbits/sec
    get averageIncomingBitrate(): number {
        return Math.floor(8 * (this.totalBytesReceivedCount - this.firstBytesReceivedCount) / (this.currentTimestamp - this.startTime));
    }

    // kbits/sec
    get currentOutgoingBitrate(): number {
        return Math.floor(8 * (this.totalBytesSentCount - this.lastBytesSent) / (this.currentTimestamp - this.lastTime));
    }

    // kbits/sec
    get currentIncomingBitrate(): number {
        return Math.floor(8 * (this.totalBytesReceivedCount - this.lastBytesReceived) / (this.currentTimestamp - this.lastTime));
    }

    set currentTime(timestamp: number) {
        this.lastTime = this.currentTimestamp;
        this.currentTimestamp = timestamp;
        if (this.startTime === 0) {
            this.startTime = timestamp - 1; // do not have zero division error
        }
    }

    set totalBytesReceived(bytesReceived: number) {
        this.lastBytesReceived = this.totalBytesReceivedCount;
        this.totalBytesReceivedCount = bytesReceived;
        if (this.firstBytesReceivedCount === 0) {
            this.firstBytesReceivedCount = bytesReceived;
        }
    }

    set totalBytesSent(bytesSent: number) {
        this.lastBytesSent = this.totalBytesSentCount;
        this.totalBytesSentCount = bytesSent;
        if (this.firstByteSentCount === 0) {
            this.firstByteSentCount = bytesSent;
        }
    }

    set totalFramesEncoded(framesEncoded: number) {
        this.lastFramesEncoded = this.totalFramesEncodedCount;
        this.totalFramesEncodedCount = framesEncoded;
        if (this.lastFramesEncoded === 0) {
            this.lastFramesEncoded = framesEncoded;
        }
    }

}
