<rent-entities-dropdown
    [withEmptyOption]="withEmptyOption"
    [withFirstOption]="withFirstOption"
    [activeEntityId]="activeEntityId"
    [placeholder]="placeholder"
    [dropdownClass]="dropdownClass"
    [isDisabled]="isDisabled"
    [isDisabledCentering]="isDisabledCentering"
    [serviceParams]="serviceParams"
    [service]="service"
    (selected)="onChangedEntity($event)"
></rent-entities-dropdown>
